import React, { useState ,useEffect} from 'react';
//import '../styles/ori_header.css';
import '../styles/ori_req.css';
import '../styles/classes.css';
import {navigate} from 'hookrouter';
import OriginatorHeader from './OriginatorHeader';
import useLocalStorage from '../hooks/validation';
import APICall from '../hooks/makeAPICall';
import useSessionValidity from '../hooks/sessionValidity';

function Classes(){
    const [username, setUsername] =  useLocalStorage('username','');
    const [role, setRole] =  useLocalStorage('role','');
    const [apiURL, setAPIURL] = useLocalStorage("api_url", "");
    const [student_id, setStudentID] =  useLocalStorage('student_id','');
    const [material_id, setMaterialID] =  useLocalStorage('material_id','');
    const [classes, setClasses] = useState("");
    const [showPlayer, setShowPlayer] = useState(false);
    const [playerLink, setPlayerLink] = useLocalStorage("player_link", "");
    const [playerTitle, setPlayerTitle] = useLocalStorage("player_title", "");
    const [prevURL, setPrevURL] = useLocalStorage("prev_url", "");
    const [sessionState, setSessionState] = useSessionValidity();

    const OpenVideoBrowser = (e) => {
        console.log(e.target.dataset.link);
        setPlayerLink(e.target.dataset.link);
        setPlayerTitle(e.target.dataset.title);
        setStudentID(e.target.dataset.studentid);
        setMaterialID(e.target.dataset.materialid);
        setPrevURL("/cms/classes");
        navigate('/videoplayer');
    }
    const closePlayer = (e) => {
        setPlayerLink("");
        setPlayerTitle("");
        setShowPlayer(false);
    }

    useEffect(()=>{
        setSessionState(true);
        let url = apiURL + "/plans/getclasses/";
        let options = {"username": username,};

        const response = APICall(url, options);
        response.then(result => {
            if(role=="student"){
                setClasses(result.classes);
            }
        });

    },[]);

return (
    <div>
          <div className="container">
              <br clear="all"/>
                <div className="d-flex justify-content-between">
                           <div className="part-1  id txt class_header">
                              CLASS NO
                           </div>

                           <div className="part-1 txt class_header">
                              PAPER
                           </div>

                           <div className="part-1 txt class_header">
                              CONTENT
                           </div>

                           <div className="part-4 txt class_header">
                              DATE
                           </div>

                            <div className="part-5 txt class_header">
                              VIDEOS & RELATED FILES
                             </div>
                  </div>
                  <div className="line_2 class_header">
                  </div>

                  <div className="d-flex flex-column ch__ori_recent_list">

                      {classes && classes.map((i) =>
                          <div className="d-flex desc-main new_line " key={i.classno}>

                              <div className="d-flex justify-content-between">
                                         <div className="part-1  id text-title class_header_2">
                                            CLASS NO
                                         </div>

                                         <div className="part-1 text-title class_header_2">
                                            PAPER
                                         </div>

                                         <div className="part-1 text-title class_header_2">
                                            CONTENT
                                         </div>

                                         <div className="part-1 text-title class_header_2">
                                            DATE
                                         </div>
                                </div>
                                <br/>
                              <div className="d-flex desc-left part-5">
                                 <div className="part-1 id text-body pointer">
                                         {i.classno}
                                 </div>
                                 <div className="part-2">
                                       {i.content && i.content.map((j)=>
                                          <div className="d-flex">
                                              <div className="part-1 text-body">
                                                  {j.paper}
                                              </div>
                                              <div className="part-1 text-body">
                                                  {j.content}
                                              </div>
                                          </div>
                                      )}
                                  </div>
                                  <div className="part-4 text-body">
                                      {i.from_date}
                                  </div>
                              </div>
                              <div className="d-flex flex-column part-5">
                                  <div className="d-flex flex-column">
                                      <div className="text-title">VIDEOS</div>
                                      <br/>
                                      <div className="d-flex">
                                          <div className="part-4 text-subtitle">
                                              <b>TITLE</b>
                                          </div>
                                          <div className="part-4 text-subtitle">
                                              <b>ACTION</b>
                                          </div>
                                          <div className="part-4 text-subtitle">
                                              <b>USED (in mins)</b>
                                          </div>
                                          <div className="part-4 text-subtitle">
                                              <b>REMAINING (in mins)</b>
                                          </div>
                                      </div>
                                      {i.material && i.material.map((k)=>
                                          <div className="videos-wrapper">
                                              {k.title == "" &&
                                                  <div className="d-flex video-link-row">
                                                          <div className="part-4 text-body">
                                                              Class {i.classno}
                                                          </div>
                                                          <div className="part-4 text-body">
                                                              {!k.time_sheet.remove_access &&
                                                                <span className="btn video-watch-btn" data-title={k.title} data-link={k.video_link} data-studentid={i.batch.student_id}  data-materialid={k.material_id} onClick={OpenVideoBrowser}>View</span>
                                                              }
                                                              {k.time_sheet.remove_access &&
                                                                  <span className="text-danger">Class access time completed</span>
                                                              }
                                                          </div>
                                                          <div className="part-4 text-body">
                                                              {k.time_sheet.time_used}
                                                          </div>
                                                          <div className="part-4 text-body">
                                                              {k.time_sheet.time_remaining}
                                                          </div>

                                                  </div>
                                              }

                                              {k.title !== "" &&
                                                  <div className="d-flex video-link-row">

                                                          <div className="part-4">
                                                              <span className="class-title">{k.title}</span>
                                                          </div>
                                                          <div className="part-4">
                                                              {!k.time_sheet.remove_access &&
                                                                <span className="btn video-watch-btn" data-title={k.title} data-link={k.video_link} data-studentid={i.batch.student_id}  data-materialid={k.material_id} onClick={OpenVideoBrowser}>View</span>
                                                              }
                                                              {k.time_sheet.remove_access &&
                                                                  <span className="text-danger">Class access time completed</span>
                                                              }
                                                              <br/>
                                                          </div>
                                                          <div className="part-4">
                                                              {k.time_sheet.time_used}
                                                          </div>
                                                          <div className="part-4">
                                                              {k.time_sheet.time_remaining}
                                                          </div>

                                                  </div>
                                              }
                                          </div>
                                      )}
                                  </div>
                                  <div className="d-flex flex-column">
                                      <div> <br/><br/> <div className="text-title">FILES</div> <br/></div>
                                  {i.class_docs && i.class_docs.map((l)=> <span><a className="video-watch-btn" href={apiURL+l.document}>{l.name}</a></span>)}<br/><br/>
                                  </div>
                                </div>

                          </div>
                      )}
                  </div>
          </div>
    </div>
  );
}

export default Classes;
