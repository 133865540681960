import React, { useState ,useEffect} from 'react';
//import '../styles/ori_header.css';
import '../styles/ori_req.css';
import {navigate} from 'hookrouter';
import OriginatorHeader from './OriginatorHeader';
import useLocalStorage from '../hooks/validation';
import APICall from '../hooks/makeAPICall';
import useSessionValidity from '../hooks/sessionValidity';

function DoubtsCorner(){
    const [username, setUsername] =  useLocalStorage('username','');
    const [role, setRole] =  useLocalStorage('role','');
    const [apiURL, setAPIURL] = useLocalStorage("api_url", "");
    const [doubts, setDoubts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sessionState, setSessionState] = useSessionValidity();

    useEffect(()=>{
        setSessionState(true);
        let url = apiURL + "/doubts/getdoubts/";
        let options = {"username": username,};

        const response = APICall(url, options);
        response.then(result => {
            if(role=="student"){
                setDoubts(result.doubts);
            }
        });

    },[]);


return (
    <div>
        <div className="ch__ori_content container">
            <br clear="all" />
            <div className="ch_ori_recent_txt text-right">
               <a href="/cms/askdoubt"><span  className="btn btn-info pointer">+ ASK DOUBT</span></a>
            </div>
            <div className="line_2">
            </div>
            <div className="ch__ori_recent_list">
                <br/>
                <h4>PENDING</h4>
                <br/><br/>
                <div className="row ">
                    <div className="col-lg-2">
                        <div className="id txt">
                            <b>DOUBT ID</b>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="ic_new txt">
                            <b>DOUBT ABOUT</b>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="ic_new txt">
                            <b>TOPIC</b>
                        </div>
                    </div>
                    <div className="col-lg-2 ">
                        <div className="ic_new txt">
                            <b>DOUBT</b>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="ic_new txt">
                            <b>ANSWER</b>
                        </div>
                    </div>
                </div><br/>
                {doubts && doubts.doubts_asked && doubts.doubts_asked.map((i)=>
                    <div>
                        <div className="row ">
                            <div className="col-lg-2">
                                <div className="id txt">
                                    {i.doubt_id}
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="ic_new txt">
                                    {i.doubt_about}
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="ic_new txt">
                                    {i.topic}
                                </div>
                            </div>
                            <div className="col-lg-2 ">
                                <div className="ic_new txt">
                                    {i.doubt}
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="ic_new txt">
                                    --
                                </div>
                            </div>
                        </div>
                    <br/>
                    </div>
                )}

                <br/><br/>
                <br/><br/>
                <h4>ANSWERED</h4>
                <br/><br/>
                {doubts && doubts.doubts_answered && doubts.doubts_answered.map((i)=>
                    <div className="row ">
                        <div className="col-lg-2">
                            <div className="id txt">
                                {i.doubt_id}
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="ic_new txt">
                                {i.doubt_about}
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="ic_new txt">
                                {i.topic}
                            </div>
                        </div>
                        <div className="col-lg-2 ">
                            <div className="ic_new txt">
                                {i.doubt}
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="ic_new txt">
                                {i.doubt_answer}
                            </div>
                        </div>
                    </div>
                )}
            </div>
      </div>
    </div>
  );
}

export default DoubtsCorner;
