import React, { useState ,useEffect} from 'react';
import {navigate} from 'hookrouter';
import useLocalStorage from '../hooks/validation';
import OriginatorHeader from './OriginatorHeader';
import sosinlogo from '../img/sosin-classes-logo.png';
import '../styles/login.css';
import '../styles/landing.css';

function LiveStream({streamlink}){


    return(
        <div className="landing-wrapper">
            <div className="landing-header">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <img src={sosinlogo} className="sosin-logo" />
                        </div>
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <div className="text-right">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="">
                <div className="container">
                    <div>
                        <iframe width="100%" height="420px" src="https://www.youtube.com/embed/vweiD8WehPc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
            
            )

}

export default LiveStream;
