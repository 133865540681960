import React, { useState, useEffect } from 'react';
import useLocalStorage from '../hooks/validation';
import useSessionValidity from '../hooks/sessionValidity';
import '../styles/ori_req.css';
import OriginatorHeader from './OriginatorHeader';
import {navigate} from 'hookrouter';
import APICall from '../hooks/makeAPICall';

function EvaluatorRejected() {

    const [username, setUsername] =  useLocalStorage('username','');
    const [apiURL, setAPIURL] = useLocalStorage("api_url", "");
    const [pending_details, setPendingDetails] = useState([]);
    const [sessionState, setSessionState] = useSessionValidity();


    useEffect(() => {
        setSessionState(true);

        let userdetails_url = apiURL + "/testseries/getevalsheets/";
        let useroptions = {"username": username, "answer_state": "rejected" };

        const user_details = APICall(userdetails_url, useroptions);
        user_details.then(response=>{
            setPendingDetails(response.eval_sheets.sheets);
        })

    }, []);

  return (
    <div className="container-fluid">
        <div className="ch__ori_content container">
            <div className="ch_ori_recent_txt">
                <div className="row ">
                  <div className="col-lg-1">
                     <div className="id txt">
                        STUDENT ID
                     </div>
                  </div>
                  <div className="col-lg-2">
                     <div className="ic_new txt">
                        NAME
                     </div>
                  </div>
                  <div className="col-lg-2">
                     <div className="ic_new txt">
                        ASSIGNED AT
                     </div>
                  </div>
                  <div className="col-lg-2">
                     <div className="ic_new txt">
                         ANSWER SHEET
                     </div>
                  </div>
                  <div className="col-lg-2">
                     <div className="ic_new txt">
                        STATUS 
                     </div>
                  </div>
                  <div className="col-lg-3">
                      <div className="ic_new txt">
                        ACTION
                       </div>
                  </div>
                </div>

            </div>
            <div className="line_2">
            </div>
            <div className="ch__ori_recent_list">

                {pending_details && 
                    <div>    
                    {pending_details.map((i) =>
                    <div className="row new_line">
                        <div className="col-lg-1">
                            <div className="id txt pointer">
                                {i.s_id}
                            </div>
                        </div>
                        <div className="col-lg-2 ">
                            <div className="ic_new txt">
                                {i.name}
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="ic_new txt">
                                {i.assigned_on}
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="ic_new txt">
                                <a href={apiURL+i.document}>DOWNLOAD</a>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="txt">
                                {i.rejection_comments}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="ic_new txt">
                                <div>
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                    </div>
                }


          </div>
        </div>
      </div>
    );
}

export default EvaluatorRejected;
