import React, { useState, useEffect } from 'react';
import useLocalStorage from '../hooks/validation';
import '../styles/ori_req.css';
import OriginatorHeader from './OriginatorHeader';
import {navigate} from 'hookrouter';
import useSessionValidity from '../hooks/sessionValidity';

function Alerts() {

  const [username, setUsername] =  useLocalStorage('username','');
  const [apiURL, setAPIURL] = useLocalStorage("api_url", "");
    const [alerts, setAlerts] = useState([]);
    const [unread, setUnread] = useState(0);
    const [sessionState, setSessionState] = useSessionValidity();

  const APICall = async (url, options) => {
        const rawResponse = await fetch(url, {
          method: 'POST',
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
          },
          body: JSON.stringify(options)
        });
        const content = await rawResponse.json();
        console.log(content);

        return content;

  }

    const viewDetail = (e) => {
        const crid = e.target.dataset.crid;
        navigate('/change_request_detail/'+crid);
    };

  useEffect(() => {
        setSessionState(true);
      let url = apiURL + "/alerts/getalerts/";
      let options = {"username": username};

      const response = APICall(url, options);

      response.then(data => {
          if(data.success) {
            setAlerts(data.alerts); 
            setUnread(data.unread); 
          }
      });
  
  }, []);

  return (
      <div className="container-fluid">
        <div className="ch__ori_content container">
          <div className="ch_ori_recent_txt">
              <div className="row ">
                  <div className="col-lg-1">
                     <div className="id txt">
                        ALERT ID
                     </div>
                  </div>
                  <div className="col-lg-2">
                     <div className="ic_new txt">
                            RECEIVED AT
                     </div>
                  </div>
                  <div className="col-lg-2">
                     <div className="ic_new txt">
                            FROM USER
                     </div>
                  </div>
                  <div className="col-lg-1 ">
                     <div className="ic_new txt">
                     </div>
                  </div>
                  <div className="col-lg-3">
                      <div className="ic_new txt">
                            MESSAGE
                       </div>
                  </div>
                   <div className="col-lg-1">
                       <div className="ic_new txt">
                       </div>
                   </div>
              </div>
          </div>
          <div className="line_2">
          </div>
          <div className="ch__ori_recent_list">
            {alerts && alerts.map((i) =>
                <div className="row new_line">
                    <div className="col-lg-1">
                        <div className="id txt pointer">
                            {i.id}
                        </div>
                    </div>
                    <div className="col-lg-2 ">
                        <div className="ic_new txt">
                        {i.received_at}
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="ic_new txt">
                        {i.from_user_name}
                        </div>
                    </div>
                    <div className="col-lg-1">
                        <div className="ic_new txt pointer underline">
                           <span onClick={viewDetail} data-crid={i.change_request_id}> {i.change_request_id}</span>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="ic_new txt">
                            {i.message}
                    </div>
                    </div>
                    <div className="col-lg-1">
                        <div className="ic_new txt">
                        </div>
                    </div>
                </div>
                )}
            </div>
          </div>
      </div>
    );
}

export default Alerts;
