import React, { useState ,useEffect, useContext} from 'react';
import '../styles/login.css';
import {navigate} from 'hookrouter';
import useLocalStorage from '../hooks/validation';
import APICall from '../hooks/makeAPICall';
import sosinlogo from '../img/sosin-classes-logo.png';
import '../styles/landing.css';

function AlreadyLoggedin(){

    const [username, setUsername] =  useLocalStorage('username','');
    const [device_token, setDeviceToken] =  useLocalStorage('device_token','');
    const [auth_key, setAuth_key] = useLocalStorage('auth_key','');
    const [apiURL, setAPIURL] = useLocalStorage("api_url", "");
    const [role, setRole] = useLocalStorage('role','');
    const [logged_in, setLoggedIn] =  useLocalStorage('logged_in','');

    const userLogoutAll = () => {
        let userdetails_url = apiURL + "/users/logoutall/";
        let useroptions = {"username": username};
        const user_details = APICall(userdetails_url, useroptions);
        user_details.then((response)=>{
            if(response.success){
                setUsername('');
                setDeviceToken('');
                setAuth_key('');
                setLoggedIn('false');
                alert("Successfully logged out all sessions.");
                navigate("/");
            }
        });
    };

    return(
        <div className="landing-wrapper">
            <div className="landing-header">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <img src={sosinlogo} className="sosin-logo" />
                        </div>
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <div className="text-right">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="ch__login_box ">
                <div className="container">
                    <div className="text-center text-danger">
                        You are already logged in from a different browser. 
                       Please logout from other device before logging in here. 

                       <span className="btn btn-info pointer" onClick={userLogoutAll}>LOGOUT ALL OTHER SESSIONS</span>
                    </div>
                </div>
            </div>
        </div>
             
            );

}

export default AlreadyLoggedin;
