import React, { useState, useEffect } from 'react';
import useLocalStorage from '../hooks/validation';
import useSessionValidity from '../hooks/sessionValidity';
import '../styles/ori_req.css';
import OriginatorHeader from './OriginatorHeader';
import {navigate} from 'hookrouter';
import APICall from '../hooks/makeAPICall';

function Profile() {

    const [username, setUsername] =  useLocalStorage('username','');
    const [apiURL, setAPIURL] = useLocalStorage("api_url", "");
    const [profile_details, setProfileDetails] = useState({});
    const [sessionState, setSessionState] = useSessionValidity();


    useEffect(() => {
        setSessionState(true);

        let userdetails_url = apiURL + "/users/getuserdetails/";
        let useroptions = {"username": username};

        const user_details = APICall(userdetails_url, useroptions);
        user_details.then(response=>{
            setProfileDetails(response.user);
        })

    }, []);

  return (
      <div className="container-fluid">
        <div className="ch__ori_content container">
          <div className="ch_ori_recent_txt">
          </div>
          <div className="line_2">
          </div>
          <div className="ch__ori_recent_list">
            <div className="row">
                <div className="col-md-3">
                    <b>Name</b><br/>
                    <span> {profile_details.name}</span> 
                    <br/>
                </div>
                <div className="col-md-3">
                    <b>Email</b><br/>
                    <span> {profile_details.email}</span> 
                </div>
                <div className="col-md-3">
                    <b>Username</b><br/>
                    <span> {username}</span> 
                </div>
                <div className="col-md-3">
                    <img src={apiURL + profile_details.profile_pic} width="240px"/>
                </div>
            </div>
          </div>
      </div>
      </div>
    );
}

export default Profile;
