import React, { useState ,useEffect} from 'react';
//import '../styles/ori_header.css';
import '../styles/ori_req.css';
import {navigate} from 'hookrouter';
import OriginatorHeader from './OriginatorHeader';
import useLocalStorage from '../hooks/validation';
import APICall from '../hooks/makeAPICall';
import useSessionValidity from '../hooks/sessionValidity';

function DoubtsPending(){
    const [username, setUsername] =  useLocalStorage('username','');
    const [role, setRole] =  useLocalStorage('role','');
    const [apiURL, setAPIURL] = useLocalStorage("api_url", "");
    const [doubts, setDoubts] = useState({});
    const [sessionState, setSessionState] = useSessionValidity();

    useEffect(()=>{
        setSessionState(true);
        let url = apiURL + "/doubts/getdoubts/";
        let options = {"username": username,};

        const response = APICall(url, options);
        response.then(result => {
            setDoubts(result.doubts);
        });

    },[]);


return (
    <div>
        <div className="ch__ori_content container">
            <br clear="all" />
            <div className="line_2">
            </div>
            <div className="ch__ori_recent_list">
                <div className="doubt-stats">
                    <div className="row ">
                        <div className="col-lg-4">
                            <h4>PENDING</h4> <br/>
                            <b>{doubts && doubts.doubts_asked_len}</b>
                        </div>
                        <div className="col-lg-4">
                            <h4>ANSWERED</h4> <br/>
                            <b><a href="/cms/doubtsanswered">{doubts && doubts.doubts_answered_len}</a></b>
                        </div>
                    </div>
                </div>
                <br/><br/>
                <div className="row ">
                    <div className="col-lg-1">
                        <div className="id txt">
                            <b>DOUBT ID</b>
                        </div>
                    </div>
                    <div className="col-lg-1">
                        <div className="id txt">
                            <b>CREATED</b>
                        </div>
                    </div>
                    <div className="col-lg-1">
                        <div className="id txt">
                            <b>FROM USER</b>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="id txt">
                            <b>BATCH</b>
                        </div>
                    </div>
                    <div className="col-lg-1">
                        <div className="ic_new txt">
                            <b>DOUBT ABOUT</b>
                        </div>
                    </div>
                    <div className="col-lg-1">
                        <div className="ic_new txt">
                            <b>TOPIC</b>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="ic_new txt">
                            <b>DOUBT</b>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="ic_new txt">
                            <b>ANSWER</b>
                        </div>
                    </div>
                </div><br/>
                {doubts && doubts.doubts_asked && doubts.doubts_asked.map((i)=>

                    <div>
                        <div className="row ">
                            <div className="col-lg-1">
                                <div className="id txt">
                                    {i.doubt_id}
                                </div>
                            </div>
                            <div className="col-lg-1">
                                <div className="id txt">
                                    {i.created}
                                </div>
                            </div>
                            <div className="col-lg-1">
                                <div className="id txt">
                                    {i.doubt_from}
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="id txt">
                                    {i.batch_name}
                                </div>
                            </div>
                            <div className="col-lg-1">
                                <div className="ic_new txt">
                                    {i.doubt_about}
                                </div>
                            </div>
                            <div className="col-lg-1">
                                <div className="ic_new txt">
                                    {i.topic}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="ic_new txt">
                                    {i.doubt}
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="ic_new txt">
                                    <a href={"/cms/doubtdetail/" + i.doubt_id} className="btn btn-info">+ANSWER</a>
                                </div>
                            </div>
                        </div>

                    <br/>
                    </div>
                )}
            </div>
      </div>
    </div>
  );
}

export default DoubtsPending;
