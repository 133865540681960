import React, { useState, useEffect } from 'react';
import useLocalStorage from '../hooks/validation';
import useSessionValidity from '../hooks/sessionValidity';
import '../styles/ori_req.css';
import OriginatorHeader from './OriginatorHeader';
import {navigate} from 'hookrouter';
import APICall from '../hooks/makeAPICall';

function AddBatchStudent({id, editMode}) {

    const [username, setUsername] =  useLocalStorage('username','');
    const [apiURL, setAPIURL] = useLocalStorage("api_url", "");
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [sessionState, setSessionState] = useSessionValidity();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [studentID, setStudentID] = useState("");
    const [mode, setMode] = useState("");
    const [orderno, setOrderNo] = useState("");
    const [paid, setPaid] = useState("");
    const [discount, setDiscount] = useState("");

    const updateName = e => setName(e.target.value);
    const updateEmail = e => setEmail(e.target.value);
    const updatePhone = e => setPhone(e.target.value);
    const updateAddress = e => setAddress(e.target.value);
    const updateStudentID = e => setStudentID(e.target.value);

    const updateOrderNo = e => setOrderNo(e.target.value);
    const updateMode = e => setMode(e.target.value);
    const updatePaid = e => setPaid(e.target.value);
    const updateDiscount = e => setDiscount(e.target.value);

    const addStudentBatch = () => {
        setIsLoading(true);
        let userdetails_url = apiURL + "/services/addbatchstudent/";
        let useroptions = {
            "name": name, "email": email, 
            "phone": phone, "address": address,
            "student_id": studentID, 
            "mode" : mode, "paid": paid, 
            "discount": discount, "orderno": orderno,
            "batch_id": id,
        };

        if (name === "" || email === "" || phone === "" || address === "" || studentID === "" || mode === "" || orderno === "" || paid === "" || discount === ""){
            alert("Please fill all the fields.");
            setIsLoading(false);
        } else {

            const user_details = APICall(userdetails_url, useroptions);
            user_details.then(response=>{
                if(response.success){
                    setIsLoading(false);
                    alert("Successfully created student account.");
                    navigate("/cms/batches/"+id);
                } else {
                    alert("Error occured in creation of account");
                }
            })
        }

    };


    useEffect(() => {
        setSessionState(true);

        if(editMode == "edit"){
            let url = apiURL + "/services/getbatchregistration/";
            let options = {
                "batch_registration_id": id
            };

            const response = APICall(url, options);
            response.then(result => {
                console.log("");
            });
        }

    }, []);

  return (
        <div className="container-fluid">
            <div className="ch__ori_content container">
                <div className="ch_ori_recent_txt">
                </div>
                <div className="line_2">
                </div>
                <div className="ch__ori_recent_list">
                    <div className="password-block">
                        <h4>Add Student</h4>
                        <br/>

                        <div className="row">
                            <div className="col-md-6">
                                <h4>Batch</h4><br/>

                                <b>Student ID</b><br/>
                                <input type="text" name="studentID" value={studentID} onChange={updateStudentID} />
                                <br/><br/>

                            </div>
                            <div className="col-md-6">
                            </div>
                        </div>

                        <br/>
                        <h4>Student Information</h4><br/>
                        <div className="row">
                            <div className="col-md-6">

                                <b>Name</b><br/>
                                <input type="text" name="name" value={name} onChange={updateName} />
                                <br/><br/>

                                <b>Phone</b><br/>
                                <input type="text" name="phone" value={phone} onChange={updatePhone} />
                                <br/><br/>

                                <b>Email</b><br/>
                                <input type="text" name="email" value={email} onChange={updateEmail} />
                                <br/><br/>

                            </div>
                            <div className="col-md-6">

                                <b>Address</b><br/>
                                <textarea type="text" name="address" value={address} onChange={updateAddress}></textarea>
                                <br/><br/>

                            </div>
                        </div>

                        <br/>
                        <h4>Payment Information</h4><br/>
                        <div className="row">
                            <div className="col-md-6">

                                <b>Order No.</b><br/>
                                <input type="text" name="orderno" value={orderno} onChange={updateOrderNo} />
                                <br/><br/>

                                <b>Paid</b><br/>
                                <input type="text" name="paid" value={paid} onChange={updatePaid} />
                                <br/><br/>

                            </div>
                            <div className="col-md-3">
                                <b>Payment Mode</b><br/>
                                <input type="text" name="mode" value={mode} onChange={updateMode} />
                                <br/><br/>

                                <b>Discount</b><br/>
                                <input type="text" name="discount" value={discount} onChange={updateDiscount} />
                                <br/><br/>

                            </div>
                        </div>
                        <br/><br/>
                        <br />
                        {!isLoading && <div className="text-right"><span className="btn btn-success" onClick={addStudentBatch}>SUBMIT</span></div> }
                        {isLoading && <span className="text-success">Loading ...</span>} 
                    </div>
              </div>
          </div>
      </div>
    );
}

export default AddBatchStudent;
