import useLocalStorage from '../hooks/validation';
import APICall from '../hooks/makeAPICall';
import {navigate} from 'hookrouter';
import React, { useState ,useEffect, useContext} from 'react';

function useSessionValidity(){
    const [username, setUsername] =  useLocalStorage('username','');
    const [device_token, setDeviceToken] =  useLocalStorage('device_token','');
    const [auth_key, setAuth_key] = useLocalStorage('auth_key','');
    const [apiURL, setAPIURL] = useLocalStorage("api_url", "http://localhost:8000");
    const [checkResponse, setCheckResponse] = useState({});
    const [validityCheck, setValidityCheck] = useState(false);

    useEffect(()=> {
        if(username === "" || auth_key ===""){
            navigate("/");
        } else {
            let url = apiURL + "/users/checkvalidity/" ;
            let options = {"username": username, "device_token":device_token};
            const user_info = APICall(url, options);
            user_info.then(response => {
                console.log(response);
                if(!response.is_valid){
                    setUsername("");
                    setAuth_key("");
                    setDeviceToken("");
                    navigate("/");
                } else {
                    setCheckResponse(response);
                }
            });

        }
    },[validityCheck]);

    return [validityCheck, setValidityCheck];
}
export default useSessionValidity;
