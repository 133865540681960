import React, { useState, useContext, useEffect } from 'react';
import './App.css';
import {useRoutes,A} from 'hookrouter';
import routes from './routes';
import useLocalStorage from './hooks/validation';
import { AppContext } from './Context';

//import NotFoundPage from './components/NoPage';
//import OriginatorHeader from './components/OriginatorHeader';


function App(props) {

  const match = useRoutes(routes);
  const [apiURL, setAPIURL] = useLocalStorage("api_url", "");

  useEffect(()=>{

      setAPIURL("http://api.sosinclasses.com");

  },[]);

  return (
        <div>
            <AppContext>
            {  match }
            </AppContext>
        </div>
    );

}

export default App;
