import React, { useState ,useEffect} from 'react';
import {navigate} from 'hookrouter';
import OriginatorHeader from './OriginatorHeader';
import useLocalStorage from '../hooks/validation';
import APICall from '../hooks/makeAPICall';
import terms from '../img/terms.jpg';
import useSessionValidity from '../hooks/sessionValidity';

function ProfileSetup(){
    const [username, setUsername] =  useLocalStorage('username','');
    const [role, setRole] =  useLocalStorage('role','');
    const [apiURL, setAPIURL] = useLocalStorage("api_url", "");
    const [userDetails, setUserDetails] = useState({});
    const [currState, setCurrState] = useState("one");
    const [newpassword, setNewPassword] = useState("");
    const [profilepic, setProfilePic] = useState([]);
    const [idproof, setIDProof] = useState([]);
    const [passwordToggle, setPasswordToggle] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [sessionState, setSessionState] = useSessionValidity();

    const updatePassword = e => setNewPassword(e.target.value);

    const updateUploadedFile = (e) => {
        setProfilePic(e.target.files[0]);
    };
    const updateUploadedProof = (e) => {
        setIDProof(e.target.files[0]);
    };
    const updatePasswordToggle = ()=> { setPasswordToggle(!passwordToggle); };

    const updateUserPassword = () => {
        setIsLoading(true);
        let userdetails_url = apiURL + "/users/changepassword/";
        let useroptions = {"username": username, "newpassword": newpassword};

        if (newpassword === ""){
            alert("Please fill all required fields and resubmit.");
            setIsLoading(false);
        } else {

            const user_details = APICall(userdetails_url, useroptions);
            user_details.then((response)=>{
                if(response.success){
                    setCurrState("two");
                    setIsLoading(false);
                    setRefresh(refresh+1);
                } else {
                    setIsLoading(false);
                    alert("Please fill all required fields and resubmit.");
                }
            })
        }

    };

    const CustomAPICall = async (url, options) => {
          const rawResponse = await fetch(url, options);
          const content = await rawResponse.json();
          console.log(content);
          //console.log(content.change_request.change_request_id);
          return content;
    }

    const uploadProfilePic = () => {
        setIsLoading(true);
        let url = apiURL + "/users/uploadprofilepic/";
        let formdata = new FormData();
        formdata.append('username', username);
        formdata.append('profilepic',profilepic );
        let options = {
              method: 'POST',
              body : formdata,
        }
        const file_upload = CustomAPICall(url, options);
        file_upload.then(response=>{
            if(response.success){
                setCurrState("three");
                setIsLoading(false);
                setRefresh(refresh+1);
            } else {
                setIsLoading(false);
                alert("Please fill all required fields and resubmit.");
            }

        });
    };

    const uploadIDProof = () => {
        setIsLoading(true);
        let url = apiURL + "/users/uploadidproof/";
        let formdata = new FormData();
        formdata.append('username', username);
        formdata.append('idproof', idproof );
        let options = {
              method: 'POST',
              body : formdata,
        }
        const file_upload = CustomAPICall(url, options);
        file_upload.then(response=>{
            if(response.success){
                setCurrState("four");
                setRefresh(refresh+1);
            } else {
                setIsLoading(false);
                alert("Please fill all required fields and resubmit.");
            }

        });
    };

    const finishUpdate = () => {
        setIsLoading(true);
        setIsSuccess(true);
        setTimeout(function(){ navigate("/cms/home"); }, 1000);

    };


    useEffect(()=>{
        setSessionState(true);
        let userdetails_url = apiURL + "/users/getuserdetails/";
        let useroptions = {"username": username};
        const user_details = APICall(userdetails_url, useroptions);
        user_details.then((response)=>{
            setUserDetails(response.user);
        })

    },[]);

    useEffect(()=>{
        console.log(refresh);
        setIsLoading(false);
    },[refresh]);

return (
    <div>
        <div className="ch__ori_content container">
            <br clear="all" />
            <div className="ch_ori_recent_txt">
                <h3> Hi {userDetails && <span>{userDetails.name}</span>} </h3>, <b> lets setup your profile ! </b>
            </div>
            <div className="line_2">
            </div>
            <div className="ch__ori_recent_list">
                {currState == "one" && 
                    <div className="password-block">
                        <h4> Please change your password </h4>
                        <br/><br/>

                        <b>Password</b><br/>
                        <div className="row">
                            <div className="col-md-6">
                                <input type={passwordToggle ? "text" : "password"} name="" value={newpassword} onChange={updatePassword} />
                            </div>
                            <div className="col-md-3">
                                <span className="btn btn-info" onClick={updatePasswordToggle}>Show/Hide</span>
                            </div>
                        </div>
                        <br/><br/>
                        <span>Its advised to choose a strong password with minimum of 8 characters.</span>
                        <br/><br/>
                        {isLoading && <span className="text-info">Loading..</span>}
                        {!isLoading && <span className="btn btn-warning" onClick={updateUserPassword}>NEXT</span>}
                        
                    </div>
                }
                {currState == "two" && 
                    <div className="profile-picture">
                        <span><b>Profile Picture</b></span><br/>
                        <span>Please upload a high quality image that is clear.</span><br/><br/>
                        <input type="file" name="profile_pic" onChange={updateUploadedFile} />
                        <br/><br/>
                        {isLoading && <span className="text-info">Loading..</span>}
                        {!isLoading && <span className="btn btn-warning" onClick={uploadProfilePic}>NEXT</span>}
                    </div>
                }
                {currState == "three" && 
                    <div className="profile-picture">
                        <span><b>ID Proof</b></span><br/>
                        <span>Please upload a high quality ID Proof - Any one of Aadhar, PAN, Passport, Driving License etc.</span>
                        <br/><br/>
                        <input type="file" name="id_proof" onChange={updateUploadedProof} />
                        <br/><br/>
                        {isLoading && <span className="text-info">Loading..</span>}
                        {!isLoading && <span className="btn btn-success" onClick={uploadIDProof}>NEXT</span>}
                    </div>
                }
                {currState == "four" && 
                    <div className="profile-picture">
                        <span><b>TERMS & CONDITIONS</b></span>
                        <br/><br/>
                        <img src={terms} width="100%"/>
                        <br/><br/>
                        {isLoading && <span className="text-info">Loading..</span>}
                        {!isLoading && <span className="btn btn-success" onClick={finishUpdate}>AGREE & FINISH</span>}
                    </div>
                }
                {isSuccess && <span className="text-success">Successfully setup the profile. Redirecting...</span>}
            </div>
        </div>
    </div>
    )
}

export default ProfileSetup;
