import React, { useState, useEffect } from 'react';
import useLocalStorage from '../hooks/validation';
import useSessionValidity from '../hooks/sessionValidity';
import '../styles/ori_req.css';
import OriginatorHeader from './OriginatorHeader';
import {navigate} from 'hookrouter';
import APICall from '../hooks/makeAPICall';

function BatchDetails({id}) {

    const [username, setUsername] =  useLocalStorage('username','');
    const [role, setRole] =  useLocalStorage('role','');
    const [apiURL, setAPIURL] = useLocalStorage("api_url", "");
    const [batchdetails, setBatchDetails] = useState({});
    const [sessionState, setSessionState] = useSessionValidity();


    useEffect(()=>{
        setSessionState(true);
        let url = apiURL + "/services/getbatchdetails/";
        let options = {"username": username,
            "batch_id": id};

        const response = APICall(url, options);
        response.then(result => {
            setBatchDetails(result.batchdetails);
        });

    },[]);



  return (
        <div className="container-fluid">
            <div className="ch__ori_content container">
                <div className="ch_ori_recent_txt">
                    <div className="row">
                        <div className="col-lg-12 text-right">
                           <a href={"/cms/addstudent/" +id+ "/create"}><span className="btn btn-info pointer" >+ ADD STUDENT</span></a>
                        </div>
                    </div>
                    <br/>
                        <h3>{batchdetails.batch_name}</h3>
                    <br/>
                    {role == "faculty" && 
                        <div className="course-stats-block">
                            <div className="row">
                                <div className="col-md-2">
                                    <span className="text-danger">
                                        CLASSES
                                        <br/>
                                        <br/>
                                        {batchdetails.classes}
                                    </span>
                                </div>
                                <div className="col-md-2">
                                    <span className="text-secondary">
                                        COURSE MATERIAL
                                        <br/>
                                        <br/>
                                        {batchdetails.course_material}
                                    </span>
                                </div>
                                <div className="col-md-2">
                                    <span className="text-warning">
                                        COURSE DOCUMENTS
                                        <br/>
                                        <br/>
                                        {batchdetails.course_material_docs}
                                    </span>
                                </div>
                                <div className="col-md-2">
                                    <span className="text-success">
                                        ASSIGNMENTS
                                        <br/>
                                        <br/>
                                        {batchdetails.assignments}
                                    </span>
                                </div>
                                <div className="col-md-2">
                                    <span className="">
                                        TESTS
                                        <br/>
                                        <br/>
                                        {batchdetails.tests}
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                    <br/>
                    <div className="row ">
                        <div className="col-lg-2">
                            <div className="id txt">
                                STUDENT ID
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="id txt">
                                NAME
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="id txt">
                                USERNAME
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="id txt">
                                JOINED
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="id txt">
                                EXPIRED
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="id txt">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="line_2">
                </div>
                <div className="ch__ori_recent_list">
                    {batchdetails && batchdetails.students && batchdetails.students.map((i) =>
                        <div className="row new_line">
                            <div className="col-lg-2">
                                <div className="id txt pointer">
                                    {i.student_id}
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="id txt pointer">
                                    {i.name}
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="id txt pointer">
                                    {i.username}
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="id txt">
                                    {i.joined}
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="id txt">
                                    {i.expired}
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="id txt">
                                    {i.is_active &&
                                        <span className="btn btn-danger">DEACTIVATE</span>
                                    }
                                    {!i.is_active && <span className="btn btn-success">ACTIVATE</span>}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default BatchDetails;
