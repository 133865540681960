import React, { useState ,useEffect} from 'react';
//import '../styles/ori_header.css';
import '../styles/ori_req.css';
import {navigate} from 'hookrouter';
import OriginatorHeader from './OriginatorHeader';
import useLocalStorage from '../hooks/validation';
import useSessionValidity from '../hooks/sessionValidity';
import APICall from '../hooks/makeAPICall';

function Home(){
    const [username, setUsername] =  useLocalStorage('username','');
    const [role, setRole] =  useLocalStorage('role','');
    const [apiURL, setAPIURL] = useLocalStorage("api_url", "");
    const [batch, setBatch] = useState("");
    const [teststats, setTestStats] = useState([]);
    const [regstats, setRegStats] = useState([]);
    const [evalstats, setEvalStats] = useState([]);
    const [sessionState, setSessionState] = useSessionValidity();

    useEffect(() => {
        setSessionState(true);
    },[]);

    useEffect(()=>{
        let url = "";
        if(role=="student"){
            url = apiURL + "/services/getregisteredbatches/";
        } else if(role=="testseriesadmin") {
            url = apiURL + "/testseries/teststats/";
        } else if(role=="studentreg") {
            url = apiURL + "/users/regstats/";
        } else if(role=="evaluator") {
            url = apiURL + "/testseries/getevalstats/";
        }
        let options = {"username": username,};

        const response = APICall(url, options);
        response.then(result => {
            if(role=="student"){
                setBatch(result.data);
            } else if (role == "testseriesadmin"){
                setTestStats(result.teststats);
            } else if (role == "studentreg"){
                setRegStats(result.regstats);
            } else if (role == "evaluator"){
                setEvalStats(result.eval_stats);
            }
        });

    },[]);

return (
    <div>
        <div className="ch__ori_content container">
            <br clear="all" />
            <div className="ch_ori_recent_txt">
                {role =="faculty" && 
                    <div className="ch_ori_stats">
                        <div className="">
                            <h2>FACULTY STATISTICS</h2>
                        </div>
                    </div>
                }
                {(role =="student" || role == "testseriesadmin") && 
                    <div className="ch_ori_stats">
                        <div className="">
                            <h2>DASHBOARD</h2>
                        </div>
                    </div>
                }
                {role =="evaluator" && 
                    <div className="ch_ori_stats">
                        <div className="">
                            <h2>EVALUATOR STATISTICS</h2>
                        </div>
                    </div>
                }
                {role =="studentreg" && 
                    <div className="ch_ori_stats">
                        <div className="">
                            <h2>STUDENT STATISTICS</h2>
                        </div>
                    </div>
                }
            
            </div>
            <div className="line_2">
            </div>
            <div className="ch__ori_recent_list">
                {role =="faculty" && 
                    <div className="row">
                        <div className="col-md-1">
                        </div>
                        <div className="col-md-2 text-center">
                            <h1 className="text-success">48</h1>
                            <br/>
                            <b>User registrations last week</b>
                        </div> 
                        <div className="col-md-1">
                        </div>
                        <div className="col-md-2 text-center">
                            <h1 className="text-warning">25</h1>
                            <br/>
                            <b>Doubts pending</b>
                        </div> 
                        <div className="col-md-1">
                        </div>
                        <div className="col-md-2 text-center">
                            <h1 className="text-info">80</h1>
                            <br/>
                            <b>Evaluations pending</b>
                        </div> 
                    </div>
                }
                {role =="student" && 
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-1">
                                </div>
                                <div className="col-md-2">
                                    <b>Student ID</b>
                                </div>
                                <div className="col-md-5">
                                    <b>Batch</b>
                                </div>
                                <div className="col-md-2">
                                    <b>Joined</b>
                                </div>
                                <div className="col-md-2">
                                    <b>Expires On</b>
                                </div>
                            </div>
                            <br/>
                            {batch && batch.map((i) => 
                                <div className="row">
                                    <div className="col-md-1">
                                    </div>
                                    <div className="col-md-2">
                                        {i.student_id}
                                    </div>
                                    <div className="col-md-5">
                                        {i.coaching_batch}
                                    </div>
                                    <div className="col-md-2">
                                        {i.joined}
                                    </div>
                                    <div className="col-md-2">
                                        {i.expired}
                                        <br/><br/>
                                        <span className="text-center"><h4>{i.expired_days}</h4> days remaining.</span>
                                    </div>
                                </div>
                            )}
                            
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <span className="text-info">If there are any corrections please email to support@sosinclasses.com</span> 
                                    </div>
                                </div>
                        </div>
                    </div>
                }
                {role =="testseriesadmin" && 
                    <div className="testseries-block">
                            {teststats && teststats.map((i)=>
                                <div className="testrow">
                                    <h5>{i.batch_name}</h5><br/>
                                    <div className="row">
                                        <div className="col-md-4"><b>TEST NAME</b></div>
                                        <div className="col-md-2"><b>PENDING</b></div>
                                        <div className="col-md-2"><b>APPROVED</b></div>
                                        <div className="col-md-2"><b>REJECTED</b></div>
                                        <div className="col-md-2"><b>EVALUATED</b></div>
                                    </div>
                                    <br/>
                                    {i.tests.map((k) =>
                                        <div className="row">
                                            <div className="col-md-4">
                                                {k.test_name}
                                            </div>
                                            <div className="col-md-2">
                                                <span className="text-center pointer underline">
                                                    <a href={"/cms/testseries/"+k.test_id+"/pending"}>{k.test_pending_len}</a>
                                                </span>
                                            </div>
                                            <div className="col-md-2">
                                                <span className="text-center pointer underline">
                                                    <a href={"/cms/testseries/"+k.test_id+"/approved"}>{k.test_approved_len}</a>
                                                </span>
                                            </div>
                                            <div className="col-md-2">
                                                <span className="text-center pointer underline">
                                                    <a href={"/cms/testseries/"+k.test_id+"/rejected"}>{k.test_rejected_len}</a>
                                                </span>
                                            </div>
                                            <div className="col-md-2">
                                                <span className="text-center pointer underline">
                                                    <a href={"/cms/testseries/"+k.test_id+"/evaluated"}>{k.test_evaluated_len}</a>
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    <br/><br/>
                                </div>
                            )}
                    </div>

                }
                {role =="studentreg" && 
                    <div className="studentreg-block">
                        <div className="row new_line">
                            <div className="col-md-6"><b>BATCH NAME</b></div>
                            <div className="col-md-4"><b>STUDENTS</b></div>
                            <br/>
                        </div>
                        {regstats && regstats.map((i) =>
                            <div className="row new_line">
                                <div className="col-md-6">{i.batch_name}</div>
                                <div className="col-md-4"><a href={"/cms/batches/" + i.batch_id }>{i.batch_len}</a></div>
                            </div>
                            )
                        }
                    </div>
                }
                {role =="evaluator" && 
                    <div className="row">
                        <div className="col-md-4">
                            <b>{evalstats.pending}</b>
                            <br/>
                            <br/>
                            PENDING 
                        </div> 
                        <div className="col-md-4">
                             <b>{evalstats.rejected}</b>
                            <br/>
                            <br/>
                            REJECTED
                        </div> 
                        <div className="col-md-4">
                             <b>{evalstats.completed}</b>
                            <br/>
                            <br/>
                            COMPLETED
                        </div> 
                    </div>

                }
            </div>
      </div>
    </div>
  );
}

export default Home;
