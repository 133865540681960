import React, { useState ,useEffect, useContext} from 'react';
import '../styles/login.css';
import {navigate} from 'hookrouter';
import useLocalStorage from '../hooks/validation';
import APICall from '../hooks/makeAPICall';
import sosinlogo from '../img/sosin-classes-logo.png';
import '../styles/landing.css';

function Workshop() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [heard, setHeard] = useState('');
    const [mode, setMode] = useState('');
    const [city, setCity] = useState('');
    const [showForm, setShowForm] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [emptyError, setEmptyError] = useState(false);
    const [apiURL, setAPIURL] = useLocalStorage("api_url", "http://api.sosinclasses.com");

    const updateName = (e) => { setName(e.target.value); }
    const updateEmail = (e) => { setEmail(e.target.value); }
    const updatePhone = (e) => { setPhone(e.target.value); }
    const updateHeard = (e) => { setHeard(e.target.value); }
    const updateMode = (e) => { setMode(e.target.value); }
    const updateCity = (e) => { setCity(e.target.value); }

    const handleSubmit = (e) => {
        setIsLoading(true);
        e.preventDefault();

        if(name==='' || email==='' || phone==='' || heard==='' || mode==='' || city===''){
            setEmptyError(true);
            setIsLoading(false);
        } else {
            setEmptyError(false);
            setIsLoading(true);
            let url = apiURL + "/users/workshop/"; //apiURL //"http://localhost:8000"+
            let options = {
                "name": name, "email": email, "phone": phone,
                "heard": heard, "mode": mode, "city": city,
            };
            const response = APICall(url, options);
            response.then(result => {
                setIsLoading(false);
                setShowForm(false);
                setIsSuccess(true);
            })
        }

    }

    return(
        <div className="landing-wrapper">
            <div className="landing-header">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <img src={sosinlogo} className="sosin-logo" />
                        </div>
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <div className="text-right">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="registration-wrapper">
                <div className="container">
                    <div className="registration-box">
                        <h3 className="text-center">Anthropology - The Success Mantra of UPSC</h3>
                        <div className="row">
                            <div className="col-md-6">
                                <br/>
                                <br/>
                                <b>WORKSHOP DETAILS</b>
                                <br/>
                                <ul>
                                    <li><b>2nd November, 2019 - 11 am</b></li>
                                    <li>FREE and open for everyone</li>
                                    <li>Attend Offline and Online</li>
                                    <li><b>Event will be LIVE STREAMED</b> for students who cannot attend offline</li> 
                                    <br/>
                                    <li>
                                        For details contact 99899 66744<br/><br/>
                                        <b>Location of workshop :</b><br/>
                                        Sosin Classes Classroom<br/>
                                        SM Plaza, 4th Floor,<br/>
                                        #1-1-18/60, Jawahar Nagar,<br/>
                                        Beside Sudarshan 35MM Theatre, RTC X Roads,<br/>
                                        Hyderabad-500020,<br/>
                                        Telangana, India.<br/>
                                    </li>
                                </ul>
                                <br/>
                                <br/>
                                <b>What will be covered in the workshop ?</b>
                                <br/>
                                <ul>
                                    <li>Why is anthropology the success mantra for UPSC CSE ?</li>
                                    <li>Dismantling popular myths related to anthropology</li>
                                    <li>Suggesting the right strategy to study the subject</li>
                                    <li>Lifelines of anthropology optional for UPSC</li>
                                    <li>Books to study for UPSC CSE Anthropology</li>
                                    <li>Brief understanding of the syllabus</li>
                                    <li>Explanation of the course to be launched in November 2019</li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                {showForm && <div className="registration-form">
                                    <br/><br/>
                                    <b> Registration </b>
                                    <br/><br/>
                                    <div className="form-box">
                                        <input className="form-control" placeholder="Name" value={name} onChange={updateName} />
                                        <br/>
                                    </div>
                                    <div className="form-box">
                                        <input className="form-control" placeholder="Email" value={email} onChange={updateEmail} />
                                        <br/>
                                    </div>
                                    <div className="form-box">
                                        <input className="form-control" placeholder="Phone" value={phone} onChange={updatePhone} />
                                        <br/>
                                    </div>
                                    <div className="form-box">
                                        <input className="form-control" placeholder="Which city/town are you located?" value={city} onChange={updateCity} />
                                        <br/>
                                    </div>
                                    <div className="form-box">
                                        <input className="form-control" placeholder="Mode - Offline/Online" value={mode} onChange={updateMode} />
                                        <br/>
                                    </div>
                                    <div className="form-box">
                                        <input className="form-control" placeholder="Where did you hear about the workshop?" value={heard} onChange={updateHeard} />
                                        <br/>
                                    </div> 
                                    <div className="form-box">
                                        <p className="text-success">All registered candidates will receive vital documents regarding the topics of discussion on the day of the workshop</p>
                                        <p> All fields are required to be filled </p>

                                        <br/>
                                    </div>
                                    <div className="form-box">
                                        {emptyError && <span className="text-danger">All fields are required.</span>}
                                        <br/>
                                    </div>
                                    <div className="form-box text-right">
                                        <br/>
                                        <br/>
                                        {!isLoading &&
                                            <button className="btn btn-success" onClick={handleSubmit}>SUBMIT</button>
                                        }
                                        {isLoading && <span className="text-info">Loading ...</span>}
                                        <br/>
                                    </div>
                                </div>     
                                 }
                                {isSuccess && <span className="text-success"><br/><br/><br/>Successfully registerd. You would be receiving email and sms updates from Sosin Classes.</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    );

}

export default Workshop;
