import React, {useEffect, useState} from 'react';
import {navigate, A} from 'hookrouter';
import useLocalStorage from '../hooks/validation';
import { Dropdown } from 'react-bootstrap';
import '../styles/menu.css';
function Menu(props) {
  const [isCommon, setIsCommon] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [role, setRole] = useLocalStorage('role','');
  const [activeTab, setActiveTab] = useLocalStorage("active_tab","home");
  const [menuDropdownState, setMenuDropdownState] = useState(false);
  const [reportDropdownState, setReportDropdownState] = useState(false);
  const [courseMenuDropdownState, setCourseMenuDropdownState] = useState(false);
    const [profilepic, setProfilePic] =  useLocalStorage('profilepic','');


  const handleNavClick = (e) => {
        const navurl = e.target.dataset.navurl;
        const tabname = e.target.dataset.tabname;
        setActiveTab(tabname);
        navigate(navurl);
  };
  const updateMenuDropdown = () => {
      if(menuDropdownState) {
          setMenuDropdownState(false);
      } else {
          setMenuDropdownState(true);
      }

  };
  const updateCourseMenuDropdown = () => {
      if(courseMenuDropdownState) {
          setCourseMenuDropdownState(false);
      } else {
          setCourseMenuDropdownState(true);
      }

  };

  const updateReportsMenuDropdown = () => {
      if(reportDropdownState) {
          setReportDropdownState(false);
      } else {
          setReportDropdownState(true);
      }

  };

  useEffect(()=>{

  },[activeTab]);


    return (
        <div className="menu">
            {role == "faculty" &&
              <div className="d-flex align-items-start flex-column">

                      <div onClick={handleNavClick} data-navurl="/cms/home" data-tabname="home" className={activeTab == 'home' ? 'active pointer' : 'not_active pointer'}>HOME</div>
                      <div  onClick={handleNavClick} data-navurl="/cms/batches" data-tabname="batches" className={activeTab == 'batches' ? 'active pointer' : 'not_active pointer'}>BATCHES</div>
                      <div  onClick={handleNavClick} data-navurl="/cms/doubtspending" data-tabname="doubtscorner" className={activeTab == 'doubtscorner' ? 'active pointer' : 'not_active pointer'}>DOUBTS CORNER</div>
                      <div  onClick={handleNavClick} data-navurl="/cms/alerts" data-tabname="alerts" className={activeTab == 'alerts' ? 'active pointer' : 'not_active pointer'}>ALERTS</div>

              </div>
            }
            {role == "student" &&
              <div className="d-flex align-items-start flex-column ">

                      <div onClick={handleNavClick} data-navurl="/cms/home" data-tabname="home" className={activeTab == 'home' ? 'active pointer ' : 'not_active pointer'}>HOME</div>
                      <div onClick={handleNavClick} data-navurl="/cms/tests" data-tabname="tests" className={activeTab == 'tests' ? 'active pointer ' : 'not_active pointer '}>TESTS</div>
                      <div onClick={handleNavClick} data-navurl="/cms/assignments" data-tabname="assignments" className={activeTab == 'assignments' ? 'active pointer ' : 'not_active pointer '}>ASSIGNMENTS</div>
                      <div onClick={handleNavClick} data-navurl="/cms/classes" data-tabname="classes" className={activeTab == 'classes' ? 'active pointer ' : 'not_active pointer ' }>CLASSES</div>
                      <div onClick={handleNavClick} data-navurl="/cms/timetable" data-tabname="timetable" className={activeTab == 'timetable' ? 'active pointer ' : 'not_active pointer '}>SCHEDULE</div>
                      <div  onClick={handleNavClick} data-navurl="/cms/doubtscorner" data-tabname="doubtscorner" className={activeTab == 'doubtscorner' ? 'active pointer' : 'not_active pointer'}>DOUBTS CORNER</div>
                      <div  onClick={handleNavClick} data-navurl="/cms/alerts" data-tabname="alerts" className={activeTab == 'alerts' ? 'active pointer p-2' : 'not_active pointer'}>ALERTS</div>

              </div>
            }
            {role == "evaluator" &&
              <div className="d-flex align-items-start flex-column">
                  <ul>
                      <div onClick={handleNavClick} data-navurl="/cms/home" data-tabname="home" className={activeTab == 'home' ? 'active pointer' : 'not_active pointer'}>HOME</div>
                      <div  onClick={handleNavClick} data-navurl="/cms/evaluatorpending" data-tabname="pending" className={activeTab == 'pending' ? 'active pointer' : 'not_active pointer'}>PENDING</div>
                      <div  onClick={handleNavClick} data-navurl="/cms/evaluatorcompleted" data-tabname="completed" className={activeTab == 'completed' ? 'active pointer' : 'not_active pointer'}>COMPLETED</div>
                      <div  onClick={handleNavClick} data-navurl="/cms/alerts" data-tabname="alerts" className={activeTab == 'alerts' ? 'active pointer' : 'not_active pointer'}>ALERTS</div>
                    </ul>
              </div>
            }
            {role == "studentreg" &&
              <div className="d-flex align-items-start flex-column">

                      <div onClick={handleNavClick} data-navurl="/cms/home" data-tabname="home" className={activeTab == 'home' ? 'active pointer' : 'not_active pointer'}>HOME</div>
                      <div  onClick={handleNavClick} data-navurl="/cms/alerts" data-tabname="alerts" className={activeTab == 'alerts' ? 'active pointer' : 'not_active pointer'}>ALERTS</div>

              </div>
            }
            {role == "testseriesadmin" &&
              <div className="d-flex align-items-start flex-column">

                      <div onClick={handleNavClick} data-navurl="/cms/home" data-tabname="home" className={activeTab == 'home' ? 'active pointer' : 'not_active pointer'}>HOME</div>
                      <div  onClick={handleNavClick} data-navurl="/cms/alerts" data-tabname="alerts" className={activeTab == 'alerts' ? 'active pointer' : 'not_active pointer'}>ALERTS</div>

              </div>
            }
            {role == "sysadmin" &&
              <div className="d-flex align-items-start flex-column ">

                      <div onClick={handleNavClick} data-navurl="/cms/home" data-tabname="home" className={activeTab == 'home' ? 'active pointer' : 'not_active pointer'}>HOME</div>
                      <div  onClick={handleNavClick} data-navurl="/cms/batches" data-tabname="batches" className={activeTab == 'batches' ? 'active pointer' : 'not_active pointer'}>BATCHES</div>
                      <div onClick={handleNavClick} data-navurl="/cms/roles" data-tabname="roles" className={activeTab == 'roles' ? 'active pointer' : 'not_active pointer'}>ROLES</div>
                      <div  onClick={handleNavClick} data-navurl="/cms/alerts" data-tabname="alerts" className={activeTab == 'alerts' ? 'active pointer' : 'not_active pointer'}>ALERTS</div>

              </div>
            }
        </div>
    );
};

export default Menu;
