import React, { useState, useEffect } from 'react';
import useLocalStorage from '../hooks/validation';
import '../styles/classes.css';
import '../styles/ori_req.css';
import OriginatorHeader from './OriginatorHeader';
import {navigate} from 'hookrouter';
import useSessionValidity from '../hooks/sessionValidity';
import APICall from '../hooks/makeAPICall';

function VideoPlayer() {

    const [username, setUsername] =  useLocalStorage('username','');
    const [apiURL, setAPIURL] = useLocalStorage("api_url", "");
    const [playerLink, setPlayerLink] = useLocalStorage("player_link", "");
    const [playerTitle, setPlayerTitle] = useLocalStorage("player_title", "");
    const [material_id, setMaterialID] = useLocalStorage("material_id", "");
    const [time_remaining, setTimeRemainingID] = useLocalStorage("time_remaining", "");
    const [access_id, setAccessID] = useLocalStorage("access_id", "");
    const [student_id, setStudentID] =  useLocalStorage('student_id','');
    const [prevURL, setPrevURL] = useLocalStorage("prev_url", "");
    const [sessionState, setSessionState] = useSessionValidity();
    const [acceptTerms, setAcceptTerms] = useState(false);

    useEffect(()=>{
        setSessionState(true);
    },[]);

    const startWatching = () => {
        setAcceptTerms(true);

        let url = apiURL + "/plans/creatematerialaccess/";
        let options = {
            "username": username,
            "material_id": material_id,
            "access_type": "start",
        };
        const response = APICall(url, options);
        response.then(result => {
            setAccessID(result.material_access.access_id);
            console.log(result);
        });
    };

    const stopWatching = () => {
        let url = apiURL + "/plans/creatematerialaccess/";
        let options = {
            "username": username,
            "material_id": material_id,
            "access_id": access_id,
            "access_type": "end",
        };
        const response = APICall(url, options);
        response.then(result => {
            console.log(result);
        });
        navigate(prevURL);
    };



  return (
      <div className="container-fluid">
            <div className="video-player">
               <div className="displaytag">{student_id}</div>
                {playerLink  !== "" && acceptTerms &&
                    <div>
                        <span className="btn btn-danger" onClick={stopWatching}>STOP WATCHING & GO BACK</span>
                        <br/>
                        {playerTitle !== "" && <h2>{playerTitle}</h2>}
                        <br/>
                        <div dangerouslySetInnerHTML={{__html:playerLink}}></div>
                    </div>
                }
                {playerTitle === "" && prevURL === "" && playerLink  === "" &&
                    <div>Something went wrong, we are working fixing it.</div>
                }
                {!acceptTerms &&
                    <div className="">
                        <h4>Video player Instructions</h4>
                        <ul>
                            <li>Each video can be viewed for 10 hrs - First time, revision and remaining time for doubts</li>
                            <li>Time tracking starts when you click on start and until you click on stop</li>
                            <li>Copyright and Infringement laws apply on all content from Sosin Classes</li>
                            <li><b className="text-danger">Please close the video player once you are done watching</b></li>
                        </ul> 
                        <br/><br/>
                        <span className="btn btn-success" onClick={startWatching}>START WATCHING</span>
                    </div>

                }
            </div> 

      </div>
    );
}

export default VideoPlayer;
