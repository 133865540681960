import React, { useState ,useEffect} from 'react';
import {navigate} from 'hookrouter';
import OriginatorHeader from './OriginatorHeader';
import useLocalStorage from '../hooks/validation';
import useSessionValidity from '../hooks/sessionValidity';
import APICall from '../hooks/makeAPICall';

function Batches(){
    const [username, setUsername] =  useLocalStorage('username','');
    const [role, setRole] =  useLocalStorage('role','');
    const [apiURL, setAPIURL] = useLocalStorage("api_url", "");
    const [regstats, setRegStats] = useState([]);
    const [sessionState, setSessionState] = useSessionValidity();

    useEffect(()=>{
        setSessionState(true);

        let url = apiURL + "/users/regstats/";
        let options = {"username": username,};

        const response = APICall(url, options);
        response.then(result => {
            setRegStats(result.regstats);
        });

    },[]);



return (
    <div>
        <div className="ch__ori_content container">
            <br clear="all" />
            <div className="ch_ori_recent_txt">
                {(role =="studentreg" || role =="faculty") && 
                    <div className="ch_ori_stats">
                        <div className="">
                            <h2>STUDENT STATISTICS</h2>
                        </div>
                    </div>
                }
            </div>
            <div className="line_2">
            </div>
            <div className="ch__ori_recent_list">
                {(role =="studentreg" || role =="faculty") && 
                    <div className="studentreg-block">
                        <div className="row new_line">
                            <div className="col-md-6"><b>BATCH NAME</b></div>
                            <div className="col-md-4"><b>STUDENTS</b></div>
                            <br/>
                        </div>
                        {regstats && regstats.map((i) =>
                            <div className="row new_line">
                                <div className="col-md-6">{i.batch_name}</div>
                                <div className="col-md-4"><a href={"/cms/batches/" + i.batch_id }>{i.batch_len}</a></div>
                            </div>
                            )
                        }
                    </div>
                }
            </div>
        </div>
    </div>

    )


}

export default Batches;
