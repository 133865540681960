import React, { useState ,useEffect} from 'react';
import {navigate} from 'hookrouter';
import OriginatorHeader from './OriginatorHeader';
import useLocalStorage from '../hooks/validation';
import APICall from '../hooks/makeAPICall';
import sosinlogo from '../img/sosin-classes-logo.png';
import '../styles/landing.css';
import '../styles/login.css';

function ForgotPassword(){

    const [apiURL, setAPIURL] = useLocalStorage("api_url", "");
    const [phone_number, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [otp, setOTP] = useState("");
    const [username, setUsername] = useState("");
    const [newpassword, setNewPassword] = useState("");
    const [passwordToggle, setPasswordToggle] = useState(false);
    const [reset_response, setResetResponse] = useState("");
    const [otp_response, setOTPResponse] = useState("");
    const [change_response, setChangeResponse] = useState("");
    const [change_state, setChangeState] = useState("user-details");

    const updatePasswordToggle = ()=> { setPasswordToggle(!passwordToggle); };

    const resetPassword = () => {
        let reset_url = apiURL + "/users/resetpassword/";
        let reset_options = {"email": email, 
            "phone_number": phone_number};

        if(email == "" || phone_number == ""){
            alert("Please fill all the required details");
        } else {
            setChangeState("loading");
            const reset_password = APICall(reset_url, reset_options);
            reset_password.then( response => {
                console.log(response);
                if(response.success){
                    setChangeState("verify-otp");
                    setUsername(response.username);
                } else {
                    setChangeState("error");
                }
                setResetResponse(response);
                
            })
        }
    
    };

    const verifyOTP = () => {
        setChangeState("loading");
        let verify_url = apiURL + "/users/resetauth/";
        let verify_options = {"otp": otp,
            "username": username };

        const verify_response = APICall(verify_url, verify_options);
        verify_response.then(response =>{
            if(response.success) {
                setChangeState("change-password");
            } else {
                setChangeState("error");
            }
            setOTPResponse(response);
        }) 
    };

    const updateEmail = (e) => {
      setEmail(e.target.value);
    }

    const updatePassword = e => setNewPassword(e.target.value);
    const updatePhoneNumber = e => setPhoneNumber(e.target.value);
    const updateOTP = e => setOTP(e.target.value);

    const changePassword = () => {
        let userdetails_url = apiURL + "/users/changepassword/";
        let useroptions = {"username": username, "newpassword": newpassword};

        if (newpassword === ""){
            alert("Please fill a required password.");
        } else {

            const user_details = APICall(userdetails_url, useroptions);
            user_details.then(response=>{
                if(response.success){
                    alert("Successfully changed passsword.");
                    navigate("/");
                } else {
                    alert("Please fill all required fields and resubmit.");
                }
            })
        }

    };

    return(
        <div className="landing-wrapper">
            <div className="landing-header">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <img src={sosinlogo} className="sosin-logo" />
                        </div>
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <div className="text-right">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="ch__ori_content container">
                <div className="ch__ori_recent_list">
                    {change_state == "loading" && <span className="text-success">Loading...</span>}
                    {change_state == "error" && <span className="text-danger">Something unexpected happened, please email support@sosinclasses.com for quick resolution.</span>}

                    {change_state == "user-details" && 
                        <div className="row">
                            <div className="col-md-6">
                                <h4>Please provide your registered details.</h4>
                                <br/>
                                    <input
                                    className="form-control email"
                                    type="text"
                                    placeholder="Email"
                                    value={email}
                                    onChange={updateEmail} />
                                    <br />
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Phone Number"
                                        value={phone_number}
                                        onChange={updatePhoneNumber} />
                                    <br />
                                    <div className="text-right"><span className="btn btn-success" onClick={resetPassword}>SUBMIT</span></div>
                            </div> 
                        </div>
                    }
                    {change_state == "verify-otp" && 
                        <div className="row">
                            <div className="col-md-6">
                                <h4>Please provide the OTP sent on your registered email address.</h4>
                                <br/>
                                    <input
                                    className="form-control"
                                    type="text"
                                    placeholder="OTP"
                                    value={otp}
                                    onChange={updateOTP} />
                                    <br />
                                    <div className="text-right"><span className="btn btn-success" onClick={verifyOTP}>SUBMIT</span></div>
                            </div> 
                        </div>
                    }

                    {change_state == "change-password" && 
                        <div className="password-block">
                            <h4> Please change your password </h4>
                            <br/><br/>

                            <b>Password</b><br/>
                            <div className="row">
                                <div className="col-md-6">
                                    <input type={passwordToggle ? "text" : "password"} name="" value={newpassword} onChange={updatePassword} />
                                </div>
                                <div className="col-md-3">
                                    <span className="btn btn-info" onClick={updatePasswordToggle}>Show/Hide</span>
                                </div>
                            </div>
                            <br/><br/>
                            <span>Its advised to choose a strong password with minimum of 8 characters.</span>
                            <br/><br/>
                            <br />
                            <div className="text-right"><span className="btn btn-success" onClick={changePassword}>SUBMIT</span></div>
                        
                        </div>
                    }
                </div>
            </div>
        </div>
    )

}

export default ForgotPassword;
