import React, { useState ,useEffect} from 'react';
//import '../styles/ori_header.css';
import Modal from './Modal';
import '../styles/ori_req.css';
import {navigate} from 'hookrouter';
import useToggle from '../hooks/ModalHook';
import OriginatorHeader from './OriginatorHeader';
import useLocalStorage from '../hooks/validation';
import useSessionValidity from '../hooks/sessionValidity';
import APICall from '../hooks/makeAPICall';
import {FaPen, FaMinusCircle} from 'react-icons/fa';
import {useDropzone} from 'react-dropzone'

function Tests(){
    const [username, setUsername] =  useLocalStorage('username','');
    const [role, setRole] =  useLocalStorage('role','');
    const [apiURL, setAPIURL] = useLocalStorage("api_url", "");
    const [batch, setBatch] = useState("");
    const [fileUploadModalOpen, setFileUploadModal] = useToggle(false);
    const [ansfiles, setAnsFiles] = useState([]);
    const [filecomments, setFileComments] = useState("");
    const [refresh, setRefresh] = useState(0);
    const [testid, setTestid] = useState('');

    const [playerLink, setPlayerLink] = useLocalStorage("player_link", "");
    const [playerTitle, setPlayerTitle] = useLocalStorage("player_title", "");
    const [prevURL, setPrevURL] = useLocalStorage("prev_url", "");
    const [student_id, setStudentID] =  useLocalStorage('student_id','');

    const onCommentsChange = e => setFileComments(e.target.value);
    const updateFileComments = e => setFileComments(e.target.value);

    const [sessionState, setSessionState] = useSessionValidity();

    const OpenVideoBrowser = (e) => {
        console.log(e.target.dataset.link);
        setPlayerLink(e.target.dataset.link);
        setPlayerTitle(e.target.dataset.title);
        setStudentID(e.target.dataset.studentid);
        console.log(playerLink, playerTitle, prevURL);
        setPrevURL("/cms/tests");
        navigate('/videoplayer');
    }

    const uploadAnswerSheet = (e) => {
        console.log(e.target.dataset);
    };
    const deleteAns = (e) => {
        let url = apiURL + "/testseries/delans/";
        let options = {"user_answer_id": e.target.dataset.ansid, "comments": ""};
        const response = APICall(url, options);
        response.then(result => {
            if(result.success){
                setRefresh(parseInt(refresh)+1);
            }
        });
    };

    const openFileUploadModal = (e) => {
        navigate("/cms/fileupload/testseriesanswer/"+e.target.dataset.testid);
    };

    useEffect(()=>{
        setSessionState(true);
    },[]);

    useEffect(()=>{
        let url = "";
        if(role=="student"){
            url = apiURL + "/testseries/gettestseries/";
        } else {
            url = apiURL + "/users/stats/";
        }
        let options = {"username": username,};

        const response = APICall(url, options);
        response.then(result => {
            if(role=="student"){
                setBatch(result.tests);
            }
        });

    },[refresh]);


return (
    <div>
        <div className="ch__ori_content container">
            <br clear="all" />
            <div className="ch_ori_recent_txt"> 
                <div className="row ">
                    <div className="col-lg-2">
                        <div className="id txt">
                            NAME
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="txt">
                            TEST BOOKLET 
                        </div>
                    </div>
                    <div className="col-lg-1">
                        <div className="txt">
                            MARKS
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className=" txt">
                            ANSWERSHEET
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className=" txt">
                            EVALUATION
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="txt">
                            KEY
                        </div>
                    </div>
                </div>
            </div>
            <div className="line_2">
            </div>
            <div className="ch__ori_recent_list">
                {batch && batch.map((i) =>
                    <div className="row new_line">
                        <div className="col-lg-2">
                            <div className="id txt pointer">
                                {i.name}
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="">
                                <a href={apiURL+i.document}>DOWNLOAD</a> 
                            </div>
                        </div>
                        <div className="col-lg-1">
                            <div className="id txt">
                                {i.total}
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="id txt">
                                {!i.user_answer_doc && i.user_answer_rejected && 
                                    <div className="">Your previous answersheet has been rejected, please correct errors below and re-upload.<br/><br/>
                                    <span className="text-danger">{i.user_answer_rejected}</span><br/><br/></div>
                                }

                                {!i.user_answer_doc && 
                                    <span className="btn btn-info pointer" onClick={openFileUploadModal} data-testid={i.id}>UPLOAD</span> 
                                }

                                {i.user_answer_doc &&
                                    <div>
                                        <div>
                                            Your uploaded answer sheet can be downloaded here.
                                        </div>
                                        <br/>
                                        <div>
                                            <a href={apiURL+ i.user_answer_doc}>DOWNLOAD ANSWER SHEET</a>
                                        </div>
                                        <br/><br/>
                                        {i.curr_state == "to-be-accepted" &&
                                            <div>
                                                <span>If you wish to delete the uploaded sheet, please <span className="text-danger pointer" onClick={deleteAns} data-ansid={i.user_answer_id}>click here</span></span>
                                            </div>
                                        }
                                        <br/><br/>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-lg-3">
                            {i.curr_state == "evaluation-accepted" &&
                                <div className="row">
                                    <div className="col-md-6">
                                        MARKS - {i.marks}
                                    </div>
                                    <div className="col-md-6">
                                        <a href={apiURL+i.evaluation_doc}>DOWNLOAD</a> 
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="col-lg-2">
                            <div className="key">
                                {i.key && i.key.map((k) => 
                                    <div className="row">
                                        <div className="col-lg-12">
                                            {k.name} <br/><br/>
                                            <span className="btn video-watch-btn" data-title={k.name} data-link={k.video_link} data-studentid={i.student_id} onClick={OpenVideoBrowser}>View</span>
                                        </div>
                                    </div>
                                    )
                                }
                                <br/><br/>
                                {i.docs && i.docs.map((l) => 
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <a href={l.document}>{l.name}</a>
                                             <br/><br/>
                                        </div>
                                    </div>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                )}
            
            </div>
      </div>
    </div>
  );
}

export default Tests;
