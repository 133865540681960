async function GetAPICall(url){
            const rawResponse = await fetch(url, {
                    method: 'GET',
                    headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                    },
            });
            const content = await rawResponse.json();
            console.log(content);
            //console.log(content.change_request.change_request_id);

            return content;
}
export default GetAPICall;
