import React, { useState ,useEffect, useContext} from 'react';
import Menu from './Menu';
import {navigate, A,useRedirect} from 'hookrouter';
import useLocalStorage from '../hooks/validation';
import { Dropdown } from 'react-bootstrap';
import dummy from '../img/default.png'
import APICall from '../hooks/makeAPICall';
import '../App.css';

function Header(props) {
  const [isNotFullPage, setIsNotFullPage] = useState(true);
  const [menuDropdownState, setMenuDropdownState] = useState(false);
  const [activeTab, setActiveTab] = useLocalStorage("active_tab","home");
    const [profilepic, setProfilePic] =  useLocalStorage('profilepic','');
    const [apiURL, setAPIURL] = useLocalStorage("api_url", "http://localhost:8000");
    const [role, setRole] =  useLocalStorage('role','');
    const [thumbpic, setThumbPic] = useState("");
    const [username, setUsername] =  useLocalStorage('username','');
    const [device_token, setDeviceToken] =  useLocalStorage('device_token','');

  const handleClick = (e) => {
        if(username !== ""){
            let setLogout_url = apiURL + "/users/logout/";
            let setLogout_options = {"username": username,
                "device_token" : device_token,
            };

            const setLogout_response = APICall(setLogout_url, setLogout_options);
            setLogout_response.then( response => {
                if(response.success){
                    localStorage.setItem("auth_key","");
                    localStorage.setItem("logged_in","false");
                    localStorage.setItem("username","");
                    localStorage.setItem("role","");
                    localStorage.setItem("device_token","");
                    navigate("/");
                }
            });
        }
      //  navigate('/');
  }
  const handleNavClick = (e) => {
        const navurl = e.target.dataset.navurl;
        const tabname = e.target.dataset.tabname;
        setActiveTab(tabname);
        navigate(navurl);
  };
  const updateMenuDropdown = () => {
      if(menuDropdownState) {
          setMenuDropdownState(false);
      } else {
          setMenuDropdownState(true);
      }

  };
  useEffect(()=>{
        let userdetails_url = apiURL + "/users/getuserdetails/";
        let useroptions = {"username": username};
        const user_details = APICall(userdetails_url, useroptions);
        user_details.then((response)=>{
            setThumbPic(response.user.profile_pic);
        });

  },[]);

    return (
      <div>
        {isNotFullPage &&
                <div className="d-flex align-items-start flex-column justify-content-around">
                    <div onClick={updateMenuDropdown} className={activeTab == 'users' ? 'active pointer ' : 'not_active pointer '}>
                        <Dropdown>
                            <Dropdown.Toggle variant="" id="usermenu-btn">
                            {thumbpic && <img src={apiURL + thumbpic} alt="Avatar" className="avatar"/> }
                            {!thumbpic && <img src={dummy} alt="Avatar" className="avatar"/> }
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item><span onClick={handleNavClick} data-navurl="/cms/profile" data-tabname="users" className={activeTab == 'profile' ? 'active pointer' : 'not_active pointer'}>Profile</span></Dropdown.Item>
                                <Dropdown.Item><span onClick={handleNavClick} data-navurl="/cms/changepassword" data-tabname="users" className={activeTab == 'profile' ? 'active pointer' : 'not_active pointer'}>Change Password</span></Dropdown.Item>
                                <Dropdown.Item>
                                    <span className="pointer" onClick={handleClick}>
                                        Logout
                                    </span>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <Menu/>
               </div>
          }
        </div>

    );
};

export default Header;
