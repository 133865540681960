import React, { useState ,useEffect} from 'react';
import useLocalStorage from '../hooks/validation';
import APICall from '../hooks/makeAPICall';
import '../styles/testserieslist.css';
import useToggle from '../hooks/ModalHook';
import useSessionValidity from '../hooks/sessionValidity';
import {navigate} from 'hookrouter';
import Modal from './Modal';

function TestseriesAnswers({testid, answerstate}){
    const [username, setUsername] =  useLocalStorage('username','');
    const [role, setRole] =  useLocalStorage('role','');
    const [apiURL, setAPIURL] = useLocalStorage("api_url", "");
    const [batch, setBatch] = useState("");
    const [testdetails, setTestDetails] = useState({});
    const [refresh, setRefresh] = useState(0);

    const [open, setOpen] = useToggle(false);
    const [evaluators,  setEvaluators] = useState([]);
    const [active_evaluator,  setActiveEvaluator] = useState("");
    const [evaluatorOpen, setEvaluatorOpen] = useToggle(false);
    const [approveActive, setApproveActive] = useState('');
    const [userAnswerActive, setUserAnswerActive] = useState('');
    const [userAnswerName, setUserAnswerName] = useState('');
    const [userAnswerSID, setUserAnswerSID] = useState('');
    const [rejectActive, setRejectActive] = useState('');
    const [rejectComments, setRejectComments] = useState('');
    const [sessionState, setSessionState] = useSessionValidity();


    const openRejectModal = (e) => {
        setOpen();
    };
    const rejectUserAnswerSubmit = (e) => {
        e.preventDefault();
        let url_new = apiURL + "/testseries/updateuseranswer/";
        let reject_options = {
                            "username": username,
                            "ua_id": rejectActive,
                            "rejection_comment": rejectComments,
                            "new_state": "rejected",
                        };

        const reject_new = APICall(url_new, reject_options);
        reject_new.then((response)=>{
            setOpen();
            alert("User answer rejected, action complete.");
            setRefresh(refresh+1);
        });

    };
    const userAnswerReject = (e) => {
        setRejectActive(e.target.dataset.uaid);
        setOpen();
    }
    const assignEvaluator = (e) => {
        setUserAnswerActive(e.target.dataset.uaid);
        setUserAnswerName(e.target.dataset.uaname);
        setUserAnswerSID(e.target.dataset.uasid);
        setEvaluatorOpen();
    }
    const onChangeEvaluator = (e) => {
        setActiveEvaluator(e.target.value);
    }

    const userAnswerApprove = (e) => {
        let url = apiURL + "/testseries/updateuseranswer/";
        let options = {
            "username": username, 
            "ua_id": e.target.dataset.uaid, 
            "new_state": "approved",
        };

        const response = APICall(url, options);
        response.then(result => {
            alert("Approved the answer sheet.");
            setRefresh(refresh+1);
        });
    }

    const assignAnswerEvaluator = () => {
        let url = apiURL + "/testseries/assignevaluator/";
        let options = {
            "username": username, 
            "ua_id": userAnswerActive, 
            "evaluator": active_evaluator, 
        };

        const response = APICall(url, options);
        response.then(result => {
            setEvaluatorOpen();
            setRefresh(refresh+1);
            alert("Assigned to Evaluator");
        });
    }

    const onChangeRejectComments = (e) => {
        setRejectComments(e.target.value);
    }
    useEffect(()=>{
        let url = apiURL + "/testseries/gettestdetails/";
        let options = {"username": username, "test_id": testid,};

        const response = APICall(url, options);
        response.then(result => {
            console.log(result);
            setTestDetails(result.test_details);
        });

    },[refresh]);

    useEffect(()=>{
        setSessionState(true);
        let url = apiURL + "/users/getusers/";
        let options = {"role": "evaluator"};

        const response = APICall(url, options);
        response.then(result => {
            setEvaluators(result.users);
        });
    },[]);

    return (
        <div className="container-fluid">
            <div className="ch__ori_content container">
                <div className="testseries-stats">
                     <div className="testrow">
                         <h5>{testdetails.batch_name}</h5><br/>
                         <div className="row">
                             <div className="col-md-4"><b>TEST NAME</b></div>
                             <div className="col-md-2"><b>PENDING</b></div>
                             <div className="col-md-2"><b>APPROVED</b></div>
                             <div className="col-md-2"><b>REJECTED</b></div>
                             <div className="col-md-2"><b>EVALUATED</b></div>
                         </div>
                         <br/>
                        <div className="row">
                            <div className="col-md-4">
                                {testdetails.test_name}
                            </div>
                            <div className="col-md-2">
                                <span className="text-center pointer underline">
                                    <a href={"/cms/testseries/"+testid+"/pending"}>{testdetails.test_pending_len}</a>
                                </span>
                            </div>
                            <div className="col-md-2">
                                <span className="text-center pointer underline">
                                    <a href={"/cms/testseries/"+testid+"/approved"}>{testdetails.test_approved_len}</a>
                                </span>
                            </div>
                            <div className="col-md-2">
                                <span className="text-center pointer underline">
                                    <a href={"/cms/testseries/"+testid+"/rejected"}>{testdetails.test_rejected_len}</a>
                                </span>
                            </div>
                            <div className="col-md-2">
                                <span className="text-center pointer underline">
                                    <a href={"/cms/testseries/"+testid+"/evaluated"}>{testdetails.test_evaluated_len}</a>
                                </span>
                            </div>
                        </div>
                     </div>
                <br/><br/>
                 
            </div>
          <div className="ch_ori_recent_txt">
              <div className="row ">
                  <div className="col-lg-1">
                     <div className="id txt">
                        STUDENT ID
                     </div>
                  </div>
                  <div className="col-lg-2">
                     <div className="ic_new txt">
                        NAME
                     </div>
                  </div>
                  <div className="col-lg-2">
                     <div className="ic_new txt">
                        UPLOADED AT
                     </div>
                  </div>
                  <div className="col-lg-2">
                     <div className="ic_new txt">
                         ANSWER SHEET
                     </div>
                  </div>
                  <div className="col-lg-2">
                     <div className="ic_new txt">
                        STATUS 
                     </div>
                  </div>
                  <div className="col-lg-3">
                      <div className="ic_new txt">
                        ACTION
                       </div>
                  </div>
              </div>
          </div>
          <div className="line_2">
          </div>
          <div className="ch__ori_recent_list">
            {testdetails && answerstate == "rejected" && 
                <div>
                {testdetails.test_rejected && testdetails.test_rejected.map((i) =>
                    <div className="row new_line">
                        <div className="col-lg-2">
                            <div className="id txt pointer">
                                {i.s_id}
                            </div>
                        </div>
                        <div className="col-lg-2 ">
                            <div className="ic_new txt">
                                {i.name}
                            </div>
                        </div>
                        <div className="col-lg-1">
                           <div className="ic_new txt">
                                {i.created}
                           </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="ic_new txt">
                                <a href={apiURL+i.document}>DOWNLOAD</a>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="ic_new txt">
                                {i.rejection_comment}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="ic_new txt">
                            </div>
                        </div>
                    </div>
                    )}
                </div>
            }
            {testdetails && answerstate == "pending" && 
                <div>
                {testdetails.test_pending && testdetails.test_pending.map((i) =>
                    <div className="row new_line">
                        <div className="col-lg-1">
                            <div className="id txt pointer">
                                {i.s_id}
                            </div>
                        </div>
                        <div className="col-lg-2 ">
                            <div className="ic_new txt">
                                {i.name}
                            </div>
                        </div>
                        <div className="col-lg-2">
                           <div className="ic_new txt">
                                {i.created}
                           </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="ic_new txt">
                                <a href={apiURL+i.document}>DOWNLOAD</a>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="ic_new txt">
                                <p> Please take an action to approve or reject</p>
                            </div>
                        </div>

                        <div className="col-lg-3">
                            <div className="ic_new txt">
                                <button className="btn btn-success" data-uaid={i.ua_id} onClick={userAnswerApprove}>APPROVE</button>        
                                &nbsp;&nbsp;&nbsp;
                                <button className="btn btn-danger" data-uaid={i.ua_id} onClick={userAnswerReject}>REJECT</button>        
                            </div>
                        </div>
                    </div>
                    )}
                </div>
            }
            {answerstate == "approved" &&  testdetails && 
                <div>    
                {testdetails.test_approved && testdetails.test_approved.map((i) =>
                <div className="row new_line">
                    <div className="col-lg-1">
                        <div className="id txt pointer">
                            {i.s_id}
                        </div>
                    </div>
                    <div className="col-lg-2 ">
                        <div className="ic_new txt">
                            {i.name}
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="ic_new txt">
                            {i.created}
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="ic_new txt">
                            <a href={apiURL+i.document}>DOWNLOAD</a>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="txt">
                            Assign evaluator or upload the evaluation sheet.
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="ic_new txt">
                        {i.evaluator == "" && 
                            <div>
                                <button className="btn btn-info" data-uaid={i.ua_id}  data-uaname={i.name} data-uasid={i.s_id} onClick={assignEvaluator}>Assign</button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <a href={"/cms/fileupload/evaluator/"+i.ua_id}><button className="btn btn-warning">UPLOAD</button></a>
                            </div>
                        }
                        {!i.evaluator == "" && 
                            <span>{i.evaluator}</span>
                        }
                        </div>
                    </div>
                </div>
                )}
                </div>
            }
            {answerstate == "evaluated" &&  testdetails && testdetails.test_evaluated && testdetails.test_evaluated.map((i) =>
                <div className="row new_line">
                    <div className="col-lg-1">
                        <div className="id txt pointer">
                            {i.s_id}
                        </div>
                    </div>
                    <div className="col-lg-2 ">
                        <div className="ic_new txt">
                            {i.name}
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="ic_new txt">
                            {i.created}
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="ic_new txt">
                            <a href={apiURL+i.document}>DOWNLOAD</a>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="ic_new txt">
                            Document evaluated.
                        </div>
                    </div>
                    <div className="col-lg-1">
                        <div className="ic_new txt">
                            {i.marks} Marks
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="ic_new txt">
                            <a href={apiURL+i.evaluated_document}>EVALUATED DOCUMENT</a>
                        </div>
                    </div>
                </div>
                )}
            </div>
          </div>

        {open && (
            <Modal open={open} toggle={setOpen}>
            <div className="modal_header">REJECT ANSWER</div>
                <br/>
                <br/>
                Rejection Comments
                <br />
                <textarea
                className="form-control"
                type="text"
                name="rejection_comment"
                placeholder="Rejection comment"
                value={rejectComments}
                onChange={onChangeRejectComments}
                ></textarea>
                <br/>
                <br/>
                <div className="btn btn-info submit" onClick={rejectUserAnswerSubmit}>
                Submit
                </div>
            </Modal>
        )}
        {evaluatorOpen && (
            <Modal open={evaluatorOpen} toggle={setEvaluatorOpen}>
            <div className="modal_header">ASSIGN EVALUATOR</div>
                <br/>
                <br />
                {userAnswerName}
                <br />
                {userAnswerSID}
                <br />
                <br />
                <select value={active_evaluator} onChange={onChangeEvaluator} name="active_evaluator" className="form-control">
                    <option value="none">--</option>
                    {evaluators.map(i => (                                       
                        <option value={i.username}>{i.name}</option>                
                    ))};                                                             
                </select> 
                <br/>
                <div className="btn btn-info submit" onClick={assignAnswerEvaluator}>
                Submit
                </div>
            </Modal>
        )}
      </div>
    )
}

export default TestseriesAnswers;
