import React from "react";

const init = { key: "", active_tab : "", api_url : "", device_token : "", 
    role: "", username : ""}

const AppState = React.createContext(init);

function AppContext(props) {

    return (
            <AppState.Provider value={init}>
                {props.children}
            </AppState.Provider>
            
            );
}

export { AppState, AppContext };
