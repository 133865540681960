async function APICall(url, options){
            const rawResponse = await fetch(url, {
                    method: 'POST',
                    headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(options)
            });
            const content = await rawResponse.json();
            console.log(content);
            //console.log(content.change_request.change_request_id);

            return content;
}
export default APICall;
