import React, { useState ,useEffect} from 'react';
//import '../styles/ori_header.css';
import '../styles/ori_req.css';
import {navigate} from 'hookrouter';
import OriginatorHeader from './OriginatorHeader';
import useSessionValidity from '../hooks/sessionValidity';
import useLocalStorage from '../hooks/validation';
import APICall from '../hooks/makeAPICall';

function Assignments(){
    const [username, setUsername] =  useLocalStorage('username','');
    const [role, setRole] =  useLocalStorage('role','');
    const [apiURL, setAPIURL] = useLocalStorage("api_url", "");
    const [batch, setBatch] = useState("");
    const [sessionState, setSessionState] = useSessionValidity();
    const [assignmentsList, setAssignmentsList] = useState([]);

    useEffect(() => {
        setSessionState(true);
        let url = apiURL + "/plans/getassignments/";
        let options = {"username": username,};

        const response = APICall(url, options);
        response.then(result => {
            setAssignmentsList(result.assignments);
        })

        console.log("assignment list",assignmentsList);
    },[]);


return (
    <div>
        <div className="ch__ori_content container">
            <br clear="all" />
            <div className="ch_ori_recent_txt">
                <div className="row ">
                    <div className="col-lg-4">
                        <div className="id txt">
                            NAME
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="txt">
                            ASSIGNMENT BOOKLET 
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="txt">
                            KEY
                        </div>
                    </div>
                </div>
            
            </div>
            <div className="line_2">
            </div>
            <div className="ch__ori_recent_list">
                {assignmentsList && assignmentsList.map((i) =>
                    <div className="row new_line">
                        <div className="col-lg-4">
                            <div className="id txt pointer">
                                {i.name}
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <a href={apiURL+i.document}>Download</a>
                        </div>
                        <div className="col-lg-4">
                            <div className="ic_new txt">
                            --
                            </div>
                        </div>

                    </div>
                )}
            </div>
      </div>
    </div>
  );
}

export default Assignments;
