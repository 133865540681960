import React, { useState, useEffect } from 'react';
import useLocalStorage from '../hooks/validation';
import useSessionValidity from '../hooks/sessionValidity';
import '../styles/ori_req.css';
import OriginatorHeader from './OriginatorHeader';
import {navigate} from 'hookrouter';
import APICall from '../hooks/makeAPICall';

function ChangePassword() {

    const [username, setUsername] =  useLocalStorage('username','');
    const [apiURL, setAPIURL] = useLocalStorage("api_url", "");
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [newpassword, setNewPassword] = useState("");
    const [passwordToggle, setPasswordToggle] = useState(false);
    const [sessionState, setSessionState] = useSessionValidity();

    const updatePassword = e => setNewPassword(e.target.value);
    const updatePasswordToggle = ()=> { setPasswordToggle(!passwordToggle); };

    const changePassword = () => {
        let userdetails_url = apiURL + "/users/changepassword/";
        let useroptions = {"username": username, "newpassword": newpassword};

        if (newpassword === ""){
            alert("Please fill a required password.");
        } else {

            const user_details = APICall(userdetails_url, useroptions);
            user_details.then(response=>{
                if(response.success){
                    alert("Successfully changed passsword.");
                    navigate("/");
                } else {
                    alert("Please fill all required fields and resubmit.");
                }
            })
        }

    };


    useEffect(() => {
        setSessionState(true);
    }, []);

  return (
      <div className="container-fluid">
        <div className="ch__ori_content container">
          <div className="ch_ori_recent_txt">
          </div>
          <div className="line_2">
          </div>
          <div className="ch__ori_recent_list">
              <div className="password-block">
                  <h4> Please change your password </h4>
                  <br/><br/>

                  <b>Password</b><br/>
                  <div className="row">
                      <div className="col-md-6">
                          <input type={passwordToggle ? "text" : "password"} name="" value={newpassword} onChange={updatePassword} />
                      </div>
                      <div className="col-md-3">
                          <span className="btn btn-info" onClick={updatePasswordToggle}>Show/Hide</span>
                      </div>
                  </div>
                  <br/><br/>
                  <span>Its advised to choose a strong password with minimum of 8 characters.</span>
                  <br/><br/>
                  <br />
                  <div className="text-right"><span className="btn btn-success" onClick={changePassword}>SUBMIT</span></div> 
              </div>
          </div>
      </div>
      </div>
    );
}

export default ChangePassword;
