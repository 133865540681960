import React, { useState, useEffect } from 'react';
import useLocalStorage from '../hooks/validation';
import useSessionValidity from '../hooks/sessionValidity';
import '../styles/ori_req.css';
import OriginatorHeader from './OriginatorHeader';
import {navigate} from 'hookrouter';
import APICall from '../hooks/makeAPICall';

function AskDoubt() {

    const [username, setUsername] =  useLocalStorage('username','');
    const [apiURL, setAPIURL] = useLocalStorage("api_url", "");
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [doubt, setDoubt] = useState("");
    const [doubtTopic, setDoubtTopic] = useState("");
    const [doubtAbout, setDoubtAbout] = useState("");
    const [sessionState, setSessionState] = useSessionValidity();

    const askDoubtPost = () => {
        setIsLoading(true);
        if(doubt === "" || doubtAbout === ""){
            setIsLoading(false);
            setIsError(true);
        } else{
            setIsError(false);
            let url = apiURL + "/doubts/createdoubt/";
            let options = {
                "doubt_from": username,
                "doubt": doubt,
                "topic": doubtTopic,
                "doubt_about": doubtAbout,
            };

            const response = APICall(url, options);

            response.then(data => {
                if(data.doubt.success) {
                    setTimeout(function(){ navigate("/cms/doubtscorner"); }, 1000);
                } else{
                    alert(data.doubt.error);
                }
            });
        }

    };

    const updateDoubtAbout = (e) => { setDoubtAbout(e.target.value); };
    const updateDoubtTopic = (e) => { setDoubtTopic(e.target.value); };
    const updateDoubt = (e) => { setDoubt(e.target.value); };


  useEffect(() => {
      setSessionState(true);
  }, []);

  return (
      <div className="container-fluid">
        <div className="ch__ori_content container">
          <div className="ch_ori_recent_txt">
          </div>
          <div className="line_2">
          </div>
          <div className="ch__ori_recent_list">
            <h2> ASK DOUBT </h2>
            <br/><br/>
            <div className="row">
                <div className="col-md-3">
                    Doubt On
                </div>
                <div className="col-md-6">
                    <input name="doubt_about" value={doubtAbout} onChange={updateDoubtAbout} />
                    <br/>
                    <span className="text-info">Please mention the class number, test or assignment</span>
                </div>
            </div>
            <br/><br/>
            <div className="row">
                <div className="col-md-3">
                    Topic
                </div>
                <div className="col-md-6">
                    <input name="doubt_topic" value={doubtTopic} onChange={updateDoubtTopic} />
                    <br/><br/>
                </div>
            </div>
            <br/><br/>
            <div className="row">
                <div className="col-md-3">
                    Doubt
                </div>
                <div className="col-md-6">
                    <textarea name="doubt_topic" value={doubt} onChange={updateDoubt}></textarea>
                    <br/><br/>
                </div>
            </div>
            <br/><br/>
            <div className="row">
                <div className="col-md-3">
                </div>
                <div className="col-md-6">
                    {isError && <span className="text-danger">Please fill all required fields.<br/><br/></span>}
                    {!isLoading &&<span className="btn btn-success pointer" onClick={askDoubtPost}>SUBMIT</span>}<br/>
                    {isLoading && <span className="text-success">Loading ....</span>}
                </div>
            </div>
          </div>
      </div>
      </div>
    );
}

export default AskDoubt;
