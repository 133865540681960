import React, { useState ,useEffect} from 'react';
//import '../styles/ori_header.css';
import Modal from './Modal';
import '../styles/ori_req.css';
import {navigate} from 'hookrouter';
import useToggle from '../hooks/ModalHook';
import OriginatorHeader from './OriginatorHeader';
import useLocalStorage from '../hooks/validation';
import APICall from '../hooks/makeAPICall';
import {useDropzone} from 'react-dropzone'
import useSessionValidity from '../hooks/sessionValidity';

function FileUpload({uploadtype, id}){

    const [filecomments, setFileComments] = useState("");
    const [username, setUsername] =  useLocalStorage('username','');
    const [apiURL, setAPIURL] = useLocalStorage("api_url", "");
    const [ansfiles, setAnsFiles] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [testid, setTestid] = useState(0);
    const [user_info, setUserInfo] = useState({});
    const [marks, setMarks] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const [sessionState, setSessionState] = useSessionValidity();


    const onCommentsChange = e => setFileComments(e.target.value);
    const updateFileComments = e => setFileComments(e.target.value);
    const updateMarks = e => setMarks(e.target.value);

    const CustomAPICall = async (url, options) => {
          const rawResponse = await fetch(url, options);
          const content = await rawResponse.json();
          console.log(content);
          //console.log(content.change_request.change_request_id);
          return content;
    }

    const uploadAnsFiles = () => {
        setIsLoading(true);
        console.log(ansfiles, filecomments);
        let url = "";
        if(uploadtype == "evaluator"){
            url = apiURL + "/testseries/uploadevaluation/";
        }
        if(uploadtype == "testseriesanswer"){
            url = apiURL + "/testseries/uploadans/";
        }
        let formdata = new FormData();
        formdata.append('comments', filecomments);
        formdata.append('username', username);
        formdata.append('ans_file', ansfiles);
        formdata.append('test_id', testid);
        if(uploadtype == "evaluator"){
            formdata.append("marks", marks);
        }
        let options = {
              method: 'POST',
              body : formdata,
        }
        console.log("options", options);
        const file_upload = CustomAPICall(url, options);
        file_upload.then(response=>{
            if(response.success){
                setUploadError(false);
                setIsSuccess(true);
                setTimeout(function(){ navigate("/cms/tests"); }, 1000);
            } else {
                setIsLoading(false);
                setIsSuccess(false);
                setUploadError(true);
            }
        });
    };

    const updateUploadedFile = (e) => {
        console.log(e.target.files[0]);
        setAnsFiles(e.target.files[0]);
    };

    useEffect(()=>{
        setSessionState(true);
        setTestid(id);

        let url = apiURL + "/testseries/getuseranswer/";
        let options = {"ua_id": id,};

        const response = APICall(url, options);
        response.then(response =>{
            console.log(response);
            setUserInfo(response.user_answer);
        }) 
    },[]);


return (
        <div className="ch__ori_content container">
            <br clear="all" />
            {uploadtype == "testseriesanswer" &&
                <a href="/cms/tests">Back to Tests</a>
            }
            {uploadtype == "evaluator" &&
                <a href="/cms/home">Back Home</a>
            }
            <div className="ch__ori_recent_list">
            <div>
                <h4>{user_info.name}</h4><br/>
                <h4>{user_info.s_id}</h4><br/><br/><br/>
                {uploadtype == "testseriesanswer" &&
                    <div>
                        <h4>Student uploading requirements</h4>
                        <ul className="unlisted" >
                            <li>Download the complete booklet, attempt in the same format.</li>
                            <li>Answer only the required number of questions.</li>
                            <li>Make a scan of the copy with proper visibility</li>
                            <li>Upload the entire answer sheet in a single file.</li>
                            <li>Upload the pages in right order.</li>
                            <li>Indicate in the index table the attempted questions.</li>
                            <li>Upload the correct document. In case of a wrong uploading, you have the option to delete and upload the correct document.</li>
                        </ul>
                    </div>
                }
                {uploadtype == "evaluator" &&
                    <div> 
                        <h4>Conditions for evaluators</h4>
                        <ul className="unlisted" >
                            <li>Check for the number of answers attempted.</li>
                            <li>Evaluate only the required number of questions.</li>
                            <li>Correct all the required questions by writing detailed comments and award marks.</li>
                            <li>Comments can also include appreciation and any feedback for evaluation.</li>
                            <li>Indicate reasons if marks are not awarded to any question.</li>
                            <li>Enter the marks awarded in the  marks table.</li>
                            <li>Please indicate specially if hand writing is not legible. Student can be penalised with negative marks for the same.</li>
                            <li>Please complete the evaluation in the prescribed time to avoid inconvenience to the student.</li>
                            <li>Once evaluation is completed, pl indicate to the team about the task completion of each paper at the time of uploading in portal.</li>
                        </ul>
                    </div>
                }
            </div>

              <div className="fileupload-block">
                  <div className="row">
                      <div className="col-md-12">
                          {uploadtype == "testseriesanswer" &&
                            <b>ANSWER SHEET UPLOAD</b>
                          }
                          {uploadtype == "evaluator" &&
                            <b>EVALUATION SHEET UPLOAD</b>
                          }
                    
                          <br/><br/>
                          <input type="file" name="ans_file" onChange={updateUploadedFile} />
                      </div>
                  </div>
                  
                  {uploadtype == "evaluator" &&
                    <div>
                        <br/><br/>
                        <div className="row">
                            <div className="col-md-4">
                                Marks:
                            </div>
                            <div className="col-md-6">
                                <input type="text" name="marks" value={marks} onChange={updateMarks}/>
                            </div>
                        </div>
                      </div>
                  }
                  <br/><br/>
                  <div className="row">
                      <div className="col-md-4">
                          Comments:
                      </div>
                      <div className="col-md-6">
                          <input type="text" name="filecomments" value={filecomments} onChange={updateFileComments}/>
                      </div>
                  </div>
                  <br/><br/>
                  <div className="row">
                      <div className="col-md-10 text-right">
                        {uploadError && <span className="text-danger">Error occured while uploading, try again.</span>}
                        {isLoading && !isSuccess && <span className="text-info">Loading ...</span>}
                        {isSuccess && <span className="text-success">Successfully uploaded the document, redirecting..</span>}
                        <br/>
                        <br/>
                        {!isLoading && <span className="btn btn-success pointer" onClick={uploadAnsFiles}>UPLOAD</span>}
                        </div>
                  </div>
              </div>
            </div>
        </div>
  );
}

export default FileUpload;
