import React from 'react';
import MainPage from './components/MainPage';
import LandingPagePortal from './components/LandingPage';
import RequestDetail from './components/RequestDetail';
import CreateChange from './components/CreateChange';
import PageHeader from './components/PageWithHeader';
import Login from './components/Login';
import Workshop from './components/WorkshopRegistration';
import LiveStream from './components/LiveStream';
import ProfileSetup from './components/ProfileSetup';
import AlreadyLoggedin from './components/AlreadyLoggedin';
import VideoPlayer from './components/VideoPlayer';
import ForgotPassword from './components/ForgotPassword';

const routes = {
    '/': () => <Login/>,
    '/alreadyloggedin': () => <AlreadyLoggedin/>,
    '/videoplayer': () => <VideoPlayer/>,
    '/workshop-registration': () => <Workshop/>,
    '/profilesetup': () => <ProfileSetup/>,
    '/forgotpassword': () => <ForgotPassword/>,
    '/workshop-livestream': () => <LiveStream/>,
    '/cms*': () => <PageHeader/>,
}

export default routes;
