import React, { useState ,useEffect} from 'react';
//import '../styles/ori_header.css';
import '../styles/ori_req.css';
import {navigate} from 'hookrouter';
import OriginatorHeader from './OriginatorHeader';
import useLocalStorage from '../hooks/validation';
import useSessionValidity from '../hooks/sessionValidity';
import APICall from '../hooks/makeAPICall';

function Timetable(){
    const [username, setUsername] =  useLocalStorage('username','');
    const [role, setRole] =  useLocalStorage('role','');
    const [apiURL, setAPIURL] = useLocalStorage("api_url", "");
    const [schedule, setSchedule] = useState([]);
    const [sessionState, setSessionState] = useSessionValidity();


    useEffect(()=>{
        setSessionState(true);
        let url = apiURL + "/plans/getschedule/";
        let options = {"username": username,};

        const response = APICall(url, options);
        response.then(result => {
            if(role=="student"){
                setSchedule(result.schedule);
            }
        });

    },[]);


return (
    <div>
        <div className="ch__ori_content container">
            <br clear="all" />
            <div className="ch_ori_recent_txt">
            </div>
            <div className="line_2">
            </div>
            <div className="ch__ori_recent_list">
            {schedule && schedule.map((i) =>
                    <div className="schedule-block">
                        <h4>{i.name}</h4>
                        <br/><br/>
                    <div className="d-flex justify-content-between hd">
                                        <div className=" txt timetable_fl  timetable_header ">
                                            <b>S. NO.</b>
                                        </div>

                                        <div className="txt timetable_fl  timetable_header ">
                                            <b>CLASS NO</b>
                                        </div>


                                        <div className="txt timetable_fl  timetable_header ">
                                            <b>DATE</b>
                                        </div>

                                        <div className=" txt timetable_fl  timetable_header ">
                                            <b>PAPER</b>
                                        </div>


                                        <div className="txt timetable_fl  timetable_header ">
                                            <b>CONTENT</b>
                                        </div>

                                        <div className=" txt timetable_fl  timetable_header ">
                                        <b>REMARKS</b>
                                        </div>

                        </div>
                        <br/><br/>
                        {i.schedule && i.schedule.map((k) =>
                       <div className="d-flex mob-block">
                                  <div className="d-flex timetable_fl">
                                        <div className="txt timetable_fl  timetable_header_2 ">
                                            <b>S. NO.</b>
                                        </div>
                                        <div className="txt timetable_fl">
                                            {k.classno}
                                        </div>
                                    </div>
                                     <div className="d-flex timetable_fl">
                                        <div className="txt timetable_fl timetable_header_2">
                                            <b>CLASS </b>
                                        </div>
                                        <div className="txt timetable_fl">
                                            {k.name}
                                        </div>
                                    </div>
                                     <div className="d-flex timetable_fl">
                                        <div className="txt timetable_fl timetable_header_2">
                                            <b>DATE</b>
                                        </div>
                                        <div className="txt timetable_fl ">
                                            {k.from_date}
                                        </div>
                                    </div>
                                        {k.topics && k.topics.map((j)=>
                                            <div className="d-flex timetable_fl_2">
                                                <div className="d-flex timetable_fl">
                                                    <div className="txt timetable_fl timetable_header_2">
                                                        <b>PAPER</b>
                                                    </div>
                                                    <div className="txt timetable_fl">
                                                        {j.paper}
                                                    </div>
                                                </div>
                                                <div className="d-flex timetable_fl">
                                                    <div className="txt timetable_fl timetable_header_2">
                                                        <b>CONTENT</b>
                                                    </div>
                                                    <div className="txt timetable_fl">
                                                        {j.content}
                                                    </div>
                                                </div>

                                            </div>
                                        )}

                                        <div className="d-flex timetable_fl">
                                            <div className="txt timetable_fl timetable_header_2">
                                            <b>REMARKS</b>
                                            </div>
                                            <div className="ic_new txt timetable_fl ">
                                            </div>
                                        </div>
                                <br/><br/>



                        </div>
                        )}
                    </div>
            )}

            </div>
      </div>
    </div>
  );
}

export default Timetable;
