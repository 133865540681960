import React, {useState} from 'react';
import PageHeaderRoutes from '../routesPageWithHeader';
import {useRoutes} from 'hookrouter';
import Header from './Header';
import Sidebar from "react-sidebar";
import '../App.css';

function PageHeader(props) {
    const routeResult = useRoutes(PageHeaderRoutes);
    const [sidebaropen,setSidebaropen]=useState(false);

    const openNav =(open)=>{
            setSidebaropen(open);
    }

    return (
        <div className="d-flex">
            <div className="header_wrapper">
              <Header/>
            </div>
            <div className="header_wrapper_2">
                <Sidebar
                    sidebar={<Header/>}
                    open={sidebaropen}
                    onSetOpen={openNav}
                    styles={{ sidebar: { background: "white" } }}>
                    <button className="openbtn" onClick={() => openNav(true)}>
                      ☰
                    </button>
                </Sidebar>
            </div>
            <div className="body-wrapper">
                    {routeResult}
            </div>
        </div>
    );

};

export default PageHeader;
