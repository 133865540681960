import React, { useState ,useEffect} from 'react';
//import '../styles/ori_header.css';
import '../styles/ori_req.css';
import {navigate} from 'hookrouter';
import OriginatorHeader from './OriginatorHeader';
import useLocalStorage from '../hooks/validation';
import APICall from '../hooks/makeAPICall';
import useSessionValidity from '../hooks/sessionValidity';

function DoubtDetail({id}){
    const [username, setUsername] =  useLocalStorage('username','');
    const [role, setRole] =  useLocalStorage('role','');
    const [apiURL, setAPIURL] = useLocalStorage("api_url", "");
    const [doubt, setDoubt] = useState({});
    const [doubtAnswer, setDoubtAnswer] = useState("");
    const [refresh, setRefresh] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [sessionState, setSessionState] = useSessionValidity();

    const updateDoubtAnswer = (e) => { setDoubtAnswer(e.target.value); };
    const submitAnswer = () => {
        setIsLoading(true);
        let url = apiURL + "/doubts/answerdoubt/";
        let options = {"username": username, 
            "doubt_id": doubt.doubt_id,
            "doubt_answer": doubtAnswer 
        };

        const response = APICall(url, options);
        response.then(result => {
            if(result.doubt.success) {
                setIsSuccess(true);
                setIsLoading(false);
                setRefresh(refresh+1);
            }
        });

    };

    useEffect(()=>{
        setSessionState(true);
        let url = apiURL + "/doubts/getdoubt/";
        let options = {"username": username, "doubt_id": id};

        const response = APICall(url, options);
        response.then(result => {
            setDoubt(result.doubt);
        });

    },[refresh]);


return (
    <div>
        <div className="ch__ori_content container">
            <br clear="all" />
            <div className="line_2">
            </div>
            <div className="ch__ori_recent_list">
                <div className="row">
                    <div className="col-md-6">
                        <h4>Doubt detail</h4>
                    </div>
                    <div className="col-md-6">
                        <a href="/cms/doubtspending">BACK TO DOUBTS</a>
                    </div>
                </div>
                <br/><br/>
                <div className="row">
                    <div className="col-md-3">
                        <b>DOUBT ID</b><br/>
                        <br/>
                        {doubt && doubt.doubt_id}
                    </div>
                    <div className="col-md-3">
                        <b>DOUBT CREATED</b><br/>
                        <br/>
                        {doubt && doubt.created}
                    </div>
                    <div className="col-md-3">
                        <b>DOUBT FROM</b><br/>
                        <br/>
                        {doubt && doubt.doubt_from}
                        
                    </div>
                </div>
                <br/><br/>
                <div className="row">
                    <div className="col-md-3">
                        <b>DOUBT ABOUT</b><br/>
                        <br/>
                        {doubt && doubt.doubt_about}
                    </div>
                    <div className="col-md-3">
                        <b>DOUBT TOPIC</b><br/>
                        <br/>
                        {doubt && doubt.topic}
                    </div>
                    <div className="col-md-3">
                        <b>BATCH</b><br/>
                        <br/>
                        {doubt && doubt.batch_name}
                    </div>
                </div>
                <br/><br/>
                <div className="row">
                    <div className="col-md-12">
                        <b>DOUBT</b>
                        <br/>
                        {doubt && doubt.doubt}
                    </div>
                </div>
                <br/><br/>
                <div className="row">
                    <div className="col-md-8">
                        <b>ANSWER</b>
                        <br/>
                        <br/>
                        {doubt && doubt.doubt_state == "asked" && 
                            <div className="doubt-form">
                                <textarea value={doubtAnswer} onChange={updateDoubtAnswer}></textarea>
                                <br/><br/>
                                <div className="text-right">
                                    {!isLoading && !isSuccess && <span className="btn btn-success pull-right" onClick={submitAnswer}>SUBMIT</span>}
                                    {isLoading && !isSuccess && <span className="text-info">Loading</span>}
                                    {isSuccess && !isLoading && <span className="text-success">Successfully submitted</span>}
                                </div>
                            </div> 
                        }
                        <br/>
                        {doubt && doubt.doubt_state == "answered" && 
                            <div className="doubt-answer">
                                {doubt.doubt_answer}
                            </div> 
                        }
                    </div>
                </div>
            </div>
      </div>
    </div>
  );
}

export default DoubtDetail;
