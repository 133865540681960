import React, { useState ,useEffect, useContext} from 'react';
import '../styles/login.css';
import {navigate} from 'hookrouter';
import useLocalStorage from '../hooks/validation';
import APICall from '../hooks/makeAPICall';
import useSessionValidity from '../hooks/sessionValidity';
import sosinlogo from '../img/sosin-classes-logo.png';
import '../styles/landing.css';
import {deviceDetect} from 'react-device-detect';
const publicIp = require('public-ip');

function Login() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loginError, setLoginError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [emptyError, setEmptyError] = useState(false);
    const [role, setRole] = useLocalStorage('role','');
    const [logged_in, setLoggedIn] =  useLocalStorage('logged_in','false');
    const [username, setUsername] =  useLocalStorage('username','');
    const [device_token, setDeviceToken] =  useLocalStorage('device_token','');
    const [device_ip, setDeviceIP] =  useLocalStorage('device_ip','default_ip');
    const [device_info, setDeviceInfo] =  useState('');
    const [auth_key, setAuth_key] = useLocalStorage('auth_key','');
    const [apiURL, setAPIURL] = useLocalStorage("api_url", "");
    const [profilepic, setProfilePic] =  useLocalStorage('profilepic','');
    const [triggerLoginSuccess, setTriggerLoginSuccess] = useState(0);
    const [triggerUserDetails, setTriggerUserDetails] = useState(0);

    const getIP = async () => { 
        try {
            const d_ip = await publicIp.v4(); 
            setDeviceIP(d_ip); 
        } catch(e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if(username !== ""){
            let userdetails_url = apiURL + "/users/getuserdetails/";
            let useroptions = {"username": username};
            const user_details = APICall(userdetails_url, useroptions);
            user_details.then((response)=>{
                setRole(response.user.role);
                console.log(response.user.role, "role #####");
                if(response.user.is_active) {
                    if(response.user.role === "studentreg") {
                            navigate('/cms/batches');
                    } else if(response.user.role === "testseriesadmin" || response.user.role === "faculty" || response.user.role === "evaluator") {
                            navigate('/cms/home');
                    } else if(response.user.role == "student" && response.user.profile_complete) {
                            setProfilePic(response.user.profile_pic);
                            navigate('/cms/home');
                    } else if(response.user.role == "student" && !response.user.profile_complete) {
                            navigate('/profilesetup');
                    }

                } else {
                    alert("Please contact support@sosinclasses.com for access.");
                }

            });
        }
    },[triggerUserDetails]);

    useEffect(() => {
        if(username !== "" && device_ip !== "" && device_info !== ""){
            let setLogin_url = apiURL + "/users/setlogin/";
            let setLogin_options = {
                "username": username, 
                "device_ip": device_ip,
                "device_info" : device_info,
            };

            const setLogin_response = APICall(setLogin_url, setLogin_options);
            setLogin_response.then(response => {
                if(response.already_loggedin){
                    navigate("/alreadyloggedin");
                } else {
                    setDeviceToken(response.device_token);
                    setTriggerUserDetails(triggerUserDetails+1);
                }
            });
        }
    },[triggerLoginSuccess]);

    const handleSubmit = (e) => {
        setIsLoading(true);
        e.preventDefault();
        if(email && password){
            setEmptyError(false);
            setLoginError(false);
            let url = apiURL + "/auth/login/";
            let options = {"username":email, "password":password};

            const response = APICall(url, options);
                response.then(result => {
                    if(result.key){
                        setAuth_key(result);
                        setLoggedIn(true);
                        setIsSuccess(true);
                        setUsername(email);

                        setTriggerLoginSuccess(triggerLoginSuccess+1);

                    } else if (result.non_field_errors){
                        setLoginError(true);
                    }
                    setIsLoading(false);
                });

            } else {
                setEmptyError(true);
            }

    }



  const updateEmail = (e) => {
    setEmail(e.target.value);
  }
  const updatePassword = (e) => {
    setPassword(e.target.value);
  }

  useEffect(() => {
        getIP();
        const device_info = deviceDetect();
        setDeviceInfo(JSON.stringify(device_info));
        setTriggerUserDetails(triggerUserDetails+1);
        setAPIURL("http://api.sosinclasses.com");
  },[]);

  return (
        <div className="landing-wrapper">
            <div className="landing-header">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <img src={sosinlogo} className="sosin-logo" />
                        </div>
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <div className="text-right">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="">
                <div className="container">
                    <div className="text-center">
                    </div>
                    <div className="ch__login_box">
                        <div className="company_name">
                            Login
                            <hr/>
                        </div>
                        <form>
                            <input
                            className="form-control email"
                            type="text"
                            placeholder="Email"
                            value={email}
                            onChange={updateEmail} />
                            <br />
                            <input
                                className="form-control"
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={updatePassword} />
                            <br />
                            {loginError && <span className="text-danger">Unable to access the portal with provided credentials. Please check and try again.</span>}
                            {emptyError && <span className="text-danger">Email and password are required.</span>}
                            <br />
                            <br />
                            <div className="text-right">
                                {!isLoading &&
                                    <span className="btn btn-primary" onClick={handleSubmit}>
                                        Submit
                                    </span>
                                }
                                {isLoading && <span className="text-info">Loading ...</span>}
                                {isSuccess && <span className="text-success">Successfully logged in, redirecting..</span>}
                            </div>
                        </form>

                        <a href="/forgotpassword">Forgot password ?</a>
 
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Login;
