import React from 'react';
import Home from './components/Home';
import Assignments from './components/Assignments';
import Tests from './components/Tests';
import Classes from './components/Classes';
import Timetable from './components/Timetable';
import DoubtsCorner from './components/DoubtsCorner';
import DoubtDetail from './components/DoubtsDetail';
import DoubtsPending from './components/DoubtsPending';
import DoubtsAnswered from './components/DoubtsAnswered';
import Alerts from './components/Alerts';
import FileUpload from './components/FileUpload';
import TestseriesAnswers from './components/TestseriesList';
import BatchDetails from './components/BatchDetails';
import Batches from './components/Batches';
import AskDoubt from './components/AskDoubt';
import ChangePassword from './components/ChangePassword';
import Profile from './components/Profile';
import EvaluatorPending from './components/EvaluatorPending';
import EvaluatorRejected from './components/EvaluatorRejected';
import EvaluatorCompleted from './components/EvaluatorCompleted';
import AddBatchStudent from './components/AddStudent';

const PageHeaderRoutes = {
    '/home': () => <Home/>,
    '/batches': () => <Batches/>,
    '/assignments': () => <Assignments/>,
    '/tests': () => <Tests/>,
    '/askdoubt': () => <AskDoubt/>,
    '/fileupload/:uploadtype/:id': ({uploadtype, id}) => <FileUpload id={id} uploadtype={uploadtype}/>,
    '/testseries/:testid/:answerstate': ({testid, answerstate}) => <TestseriesAnswers testid={testid} answerstate={answerstate}/>,
    '/batches/:id': ({id}) => <BatchDetails id={id}/>,
    '/classes': () => <Classes/>,
    '/changepassword': () => <ChangePassword/>,
    '/profile': () => <Profile/>,
    '/evaluatorpending': () => <EvaluatorPending/>,
    '/evaluatorrejected': () => <EvaluatorRejected/>,
    '/evaluatorcompleted': () => <EvaluatorCompleted/>,
    '/timetable': () => <Timetable/>,
    '/addstudent/:id/:editMode': ({id, editMode}) => <AddBatchStudent id={id} editMode={editMode} />,
    '/doubtscorner': () => <DoubtsCorner/>,
    '/doubtspending': () => <DoubtsPending/>,
    '/doubtdetail/:id': ({id}) => <DoubtDetail id={id}/>,
    '/doubtsanswered': () => <DoubtsAnswered/>,
    '/alerts': () => <Alerts/>,
};


export default PageHeaderRoutes;
